import React, {useState} from "react";
import {Form, Card, Tabs, Steps} from 'antd';
import jsonfile from './employee.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import AccountInfo from "./account_info/AccountInfo";
import PersonalInfo from "./personal_info/PersonalInfo";
import EmployeeIdentity from "./employee_identity/EmployeeIdentity";
import EmploymentInfo from "./employement_info/EmploymentInfo";
import BeneficiaryInfo from "./beneficiary_info/BeneficiaryInfo";
import EducationInfo from "./education_info/EducationInfo";
import SkillInfo from "./skill_info/SkillInfo";
import LanguageInfo from "./language_info/LanguageInfo";
import Documents from "./documents/Documents";
import WorkingHistory from "./working_history/WorkingHistory";

const { TabPane } = Tabs;

const NewEmployee = (props) => {


    const [empId, setEmpId] = useState();

    const handleCallback = (childData) =>{
        // console.log(childData);
        setEmpId(childData)
    }

    // console.log("EMPID: ", empId);

    const userID = props.match.params.id;
    // console.log(userID)
    let userData = [];

    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        // userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        // endpoint = jsonfile.urls.edit+"/"+userID;
        // redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const [selectedTab, setSelectedTab] = useState(1);
    const tabCount = 11;


    const changeTab = () => {
        setSelectedTab((selectedTab + 1) % tabCount)
    }

    const tabChangeButton = () => {
        let button = "";
        if(selectedTab == 1) {
            button = '<Button type="primary" onClick={() => setSelectedTab((selectedTab + 1) % tabCount)} style={{float:"right", marginRight: "5px"}}>Next</Button>';
        } else if(selectedTab < 10) {
            button = '<Button type="info" onClick={() => setSelectedTab((selectedTab - 1))} htmlType="back" style={{float:"right"}}>Back</Button>' +
                '<Button type="primary" onClick={() => setSelectedTab((selectedTab + 1) % tabCount)} style={{float:"right", marginRight: "5px"}}>Next</Button>'
        }

        return button;
    }

    return (
        <Card title={Title} extra={
            isEdit === 1
                ? CisUI().addAction('../../'+jsonfile.urls.list)
                : CisUI().addAction('../'+jsonfile.urls.list)
        }>
            {/*<Form*/}
            {/*    form={form}*/}
            {/*    {...CisUI().formItemLayout}*/}
            {/*    name="input"*/}
            {/*    className="ant-advanced-search-form"*/}
            {/*    onFinish={onFinish}*/}
            {/*    initialValues={{ remember: true }}*/}
            {/*>*/}

                {/*<Tabs activeKey={selectedTab.toString()} style={{ marginBottom: 32 }}>*/}
                <Tabs defaultActiveKey={1} style={{ marginBottom: 32 }}>
                    <TabPane tab="Account Info." key="1">
                        <AccountInfo empid={handleCallback} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Personal Info." key="2">
                        <PersonalInfo empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Employment" key="3">
                        <EmploymentInfo empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Employee Identity" key="4">
                        <EmployeeIdentity empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Beneficiary" key="5">
                        <BeneficiaryInfo empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Working History" key="6">
                        <WorkingHistory empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Education" key="7">
                        <EducationInfo empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Skills" key="8">
                        <SkillInfo empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Languages" key="9">
                        <LanguageInfo empid={empId} editEmpId={userID}/>
                    </TabPane>
                    <TabPane tab="Documents" key="10">
                        <Documents empid={empId} editEmpId={userID}/>
                    </TabPane>
                </Tabs>

                {/*{*/}
                {/*    selectedTab == 1 &&*/}
                {/*    <Button type="primary" onClick={() => setSelectedTab((selectedTab + 1) % tabCount)} style={{float:"right"}}>Next</Button>*/}
                {/*}*/}

                {/*{*/}
                {/*    selectedTab > 1 && selectedTab < 10 &&*/}
                {/*    <>*/}
                {/*        <Button type="info" onClick={() => setSelectedTab((selectedTab - 1))} htmlType="back" style={{float:"right"}}>Back</Button>*/}
                {/*        <Button type="primary" onClick={() => setSelectedTab((selectedTab + 1) % tabCount)} style={{float:"right", marginRight: "5px"}}>Next</Button>*/}
                {/*    </>*/}
                {/*}*/}
                {/*{*/}
                {/*    selectedTab == 10 &&*/}
                {/*    <>*/}
                {/*        <Row gutter={24}>*/}
                {/*            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>*/}
                {/*                <Button type="primary" htmlType="submit">*/}
                {/*                    Submit*/}
                {/*                </Button>*/}
                {/*                <Button type="info" onClick={() => setSelectedTab((selectedTab - 1))} htmlType="back">Back</Button>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    </>*/}
                {/*}*/}

            {/*</Form>*/}

        </Card>
    );
}

export default NewEmployee;