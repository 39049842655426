import React from "react";
import {Route, Switch} from "react-router-dom";

import EmployeeList from "../../containers/yotta/hrm/employee/EmployeeList";
import NewEmployee from "../../containers/yotta/hrm/employee/NewEmployee";
import DepartmentList from "../../containers/yotta/hrm/department/DepartmentList";
import NewDepartment from "../../containers/yotta/hrm/department/NewDepartment";
import DesignationList from "../../containers/yotta/hrm/designation/DesignationList";
import NewDesignation from "../../containers/yotta/hrm/designation/NewDesignation";
import EmploymentTypeList from "../../containers/yotta/hrm/employment_type/EmploymentTypeList";
import NewEmploymentType from "../../containers/yotta/hrm/employment_type/NewEmploymentType";
import ShiftList from "../../containers/yotta/hrm/shift/ShiftList";
import NewShift from "../../containers/yotta/hrm/shift/NewShift";
import EditShift from "../../containers/yotta/hrm/shift/EditShift";
import PayrollList from "../../containers/yotta/hrm/payroll/PayrollList";
import NewPayroll from "../../containers/yotta/hrm/payroll/NewPayroll";
import SalaryList from "../../containers/yotta/hrm/salary/SalaryList";
import NewSalary from "../../containers/yotta/hrm/salary/NewSalary";

const HRM = ({match}) => (
    <Switch>
        <Route path={`${match.url}/empaccounts`} component={EmployeeList}/>
        <Route path={`${match.url}/newempaccount`} component={NewEmployee}/>
        <Route path={`${match.url}/empaccountedit/:id`} component={NewEmployee}/>
        <Route path={`${match.url}/departments`} component={DepartmentList}/>
        <Route path={`${match.url}/newdepartment`} component={NewDepartment}/>
        <Route path={`${match.url}/departmentedit/:id`} component={NewDepartment}/>
        <Route path={`${match.url}/designation`} component={DesignationList}/>
        <Route path={`${match.url}/newdesignation`} component={NewDesignation}/>
        <Route path={`${match.url}/designationedit/:id`} component={NewDesignation}/>
        <Route path={`${match.url}/employmenttype`} component={EmploymentTypeList}/>
        <Route path={`${match.url}/newemploymenttype`} component={NewEmploymentType}/>
        <Route path={`${match.url}/employmenttypeedit/:id`} component={NewEmploymentType}/>
        <Route path={`${match.url}/shift`} component={ShiftList}/>
        <Route path={`${match.url}/newshift`} component={NewShift}/>
        <Route path={`${match.url}/shiftedit/:id`} component={EditShift}/>
        <Route path={`${match.url}/payroll`} component={PayrollList}/>
        <Route path={`${match.url}/newpayroll`} component={NewPayroll}/>
        <Route path={`${match.url}/payrolledit/:id`} component={NewPayroll}/>
        <Route path={`${match.url}/salarylist`} component={SalaryList}/>
        <Route path={`${match.url}/newsalary`} component={NewSalary}/>
        <Route path={`${match.url}/salaryedit/:id`} component={NewSalary}/>
    </Switch>
);

export default HRM;
