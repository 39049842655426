import {Form, Row, Col, Input, Button, Card, Collapse, Modal, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './transaction.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import 'moment/locale/es-us';
import StaticSelectedData from "../../../../util/StaticSelectData";

const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const TransactionEdit = (props) => {

    const formRef = React.createRef();
    const [form] = Form.useForm();
    const history = useHistory();

    const [title, setTitle] = useState("");
    const [vendor, setVendor] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);
    const [project, setProject] = useState([]);
    const [cashaccount, setCashAccount] = useState([]);
    const [isCash, setIsCash] = useState(true);
    const [benificiariesData, setBenificiaryData] = useState([]);

    const [bankaccounts, setBankAccounts] = useState([]);

    let Vendors = [];
    const Companies = SelectData("company/companylists");
    const PaymentMethod = SelectData("paymentmethod");
    const BankAccount = SelectData("bankaccount");
    const Benificiary = SelectData("accountbenificary");


    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                        setBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    const userID = props.match.params.id;
    // let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let endpoint = "accounts/newtrxrcv";
    let redirectto = "../" + jsonfile.urls.alltransactions;

    if (isEdit === 1) {
        // userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        // console.log(userData);

        // setTitle(jsonfile.edittitle);
        endpoint = "accounts/trxrcvupd" + "/" + userID;
        redirectto = "../../" + jsonfile.urls.alltransactions;
    }

    const [userData, setUserData] = useState([]);
    let BenificaryData = [];

    const getEditData = async () => {
        axios.get(Config.apiserver+jsonfile.urls.view + "/" + userID)
            .then(res => {
                console.log(res.data)
                setUserData(res.data.data);
                setFund(res.data.fund);
                setExpense(res.data.expense);
                setAccount(res.data.accounts);
                setCashAccount(res.data.cashaccounts);
                setBankAccounts(res.data.bank);
                setVendor(res.data.vendor);
                setCustomer(res.data.customer);
                setEmployee(res.data.employee);

                let payment_method = res.data.data.type;

                if(payment_method === 1)
                {
                    setIsCash(true);
                }
                else {
                    setIsCash(false);
                }

                if(res.data.debit > 0) {
                    setTitle("Receipt Details");
                }
                else {
                    setTitle("Payment Details");
                }

                // formRef.current.setFieldsValue({
                //     bank_account_id : null,
                //     reference : ""
                // });

                let benificiary_type = res.data.data.benificiary_type;

                if(benificiary_type === 2) {
                    BenificaryData = res.data.customer;
                    setBenificiaryData(res.data.customer);
                }
                else if(benificiary_type === 3) {
                    BenificaryData = res.data.vendor;
                    setBenificiaryData(res.data.vendor);
                }
                else if(benificiary_type === 4) {
                    BenificaryData = res.data.employee;
                    setBenificiaryData(res.data.employee);
                }
                else if(benificiary_type === 5) {
                    BenificaryData = res.data.accounts;
                    setBenificiaryData(res.data.accounts);
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        getEditData()
    },[]);

    // console.log(accounts);


    const updateCompanyList = () => {
        axios.get("billentrydata?com_id="+userData.company_id)
            .then((res) => {
                console.log(res.data);
                if(res.data.status === 1) {
                    setVendor(res.data.vendor);
                    setAccount(res.data.account);
                    setPayable(res.data.payable);
                    setFund(res.data.fund);
                    setExpense(res.data.expense);
                    setBankAccounts(res.data.bank);
                    setEmployee(res.data.employee);
                    setCustomer(res.data.customer);
                    setProject(res.data.project);
                    setCashAccount(res.data.cashaccount);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const parsed = queryString.parse(props.location.search);

    const po = parsed.po;
    let PORData = [];
    let poid = 0;
    if(po > 0) {
        poid = po;
    }


    const onFinish = (values) => {
        console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    document.getElementById("loader").style.display = "none";
                    //history.push(redirectto);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                    document.getElementById("loader").style.display = "none";
                }
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                document.getElementById("loader").style.display = "none";
            });
    };

    
    const onPaymentMethodChange = (value) => {
        console.log("mehtod" + value);
        if(value === 1) {
            //document.getElementById("bankaccount").style.display = "block";
            setIsCash(true);
        }
        else {
            //document.getElementById("bankaccount").style.display = "none";
            setIsCash(false);
        }
        formRef.current.setFieldsValue({
            bank_account_id : null,
            reference : ""
        });
    }

    const onBankAccountChange = (value) => {
        let ref = "";
        bankaccounts.map((items, index) => {
            if (items.id === value) {
                if (items.last_issued_check > 0) {
                    ref = parseInt(items.last_issued_check) + 1;
                } else {
                    ref = items.starting_check_no;
                }
            }
        });
    }

    const onBenificaryChange = (e) => {
        //setBenificiaryselected(e.target.value);
        console.log("Selected : ",e);
        if(e === 2) {
            BenificaryData = customer;
            setBenificiaryData(customer);
        }
        else if(e === 3) {
            BenificaryData = vendor;
            setBenificiaryData(vendor);
        }
        else if(e === 4) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        }
        else if(e === 5) {
            BenificaryData = accounts;
            setBenificiaryData(accounts);
        }
    }

    const noBillField = (
        userData == "" ? CisUI().showLoading1 : (
        <>

            <div className="row">

                <div className="col-md-4">
                    <Form.Item
                        name="date"
                        label="Date"
                        initialValue={moment(userData.date)}
                        rules={[
                            {
                                required: true,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                    placeholder="Date"/>
                    </Form.Item>
                </div>
                {
                    CisUI().getUserInfo('com_id') > 0 ? <></> :
                <div className="col-md-4">
                    <Form.Item
                        name="com_id"
                        initialValue={userData.company_id}
                        label="Company"
                        rules={[
                            {
                                required: true,
                                message: "Select an company"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an company"
                            optionFilterProp="children"
                            onChange={onCompanyChange}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                Companies.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>
                }


                <div className="col-md-4">
                    <Form.Item
                        name="reference_no"
                        label="Reference#"
                        initialValue={userData.reference}
                        rules={[
                            {
                                required: false,
                                message: "Enter reference number"
                            },
                        ]}
                    >
                        <Input className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="project_id"
                        initialValue={userData.project_id === 0 ? "" : userData.project_id}
                        label="Project"
                        rules={[
                            {
                                required: false,
                                message: "Select a project"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an project"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                project.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.project_name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="fund_program"
                        initialValue={userData.fund_program}
                        label="Fund Program"
                        rules={[
                            {
                                required: false,
                                message: "Select a fund program"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a fund program"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                fund.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="func_expense_id"
                        initialValue={userData.func_expense_id}
                        label="Functional Expense"
                        rules={[
                            {
                                required: false,
                                message: "Select a functional expense"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select functional expense"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                expense.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="restriction_type"
                        label="Restriction Type"
                        initialValue={userData.restriction_type}
                        rules={[
                            {
                                required: false,
                                message: "Select a restriction type"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a restriction type"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                StaticSelectedData("restrictiontype").map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="benificiary_type"
                        initialValue={userData.benificiary_type}
                        label="Payment Source"
                        rules={[
                            {
                                required: true,
                                message: "Select a payment source"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an payment source"
                            optionFilterProp="children"
                            onChange={onBenificaryChange}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                Benificiary.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="benificiary_id"
                        initialValue={userData.benificiary_id}
                        label="Benificiary"
                        rules={[
                            {
                                required: true,
                                message: "Select a benificiary"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an benificiary"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                benificiariesData.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="account_id"
                        initialValue={userData.account_id}
                        label="Account"
                        rules={[
                            {
                                required: true,
                                message: "Select an account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an account"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                accounts.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="title"
                        label="Description"
                        initialValue={userData.title}
                        rules={[
                            {
                                required: true,
                                message: "Enter description here"
                            },
                        ]}
                    >
                        <Input placeholder="Enter description here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="payment_method"
                        label="Payment Method"
                        initialValue={userData.type}
                        rules={[
                            {
                                required: true,
                                message: "Select payment method"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a payable account"
                            optionFilterProp="children"
                            onChange={onPaymentMethodChange}
                            allowClear={true}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                PaymentMethod.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4" id="bankaccount" >
                    <Form.Item
                        name="bank_account_id"
                        label="Bank Account/Cash Account"
                        initialValue={userData.bank_account_id}
                        rules={[
                            {
                                required: true,
                                message: "Select a bank account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a bank account"
                            optionFilterProp="children"
                            onChange={onBankAccountChange}
                            allowClear={true}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                isCash ?
                                    cashaccount.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                    :
                                    bankaccounts.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.account_no}-{items.account_name}({items.bank_name})</Option>
                                    )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="amount"
                        label="Amount"
                        initialValue={userData.debit == "" ? userData.credit : userData.debit}
                        rules={[
                            {
                                required: true,
                                message: "Enter amount here"
                            },
                        ]}
                    >
                        <Input placeholder="Enter amount here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="remark"
                        label="Remark/Memo"
                        initialValue={userData.notes}
                        rules={[
                            {
                                required: false,
                                message: "Enter something here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter somthing ehre"/>
                    </Form.Item>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                    <Button id="submitbutton" type="primary" htmlType="submit">
                        Save
                    </Button>
                    <a className="ant-btn ant-alert-info" onClick={() => history.goBack()} >Cancel</a>
                </div>
            </div>
        </>
        )
    );


    return (
        <Card title={title} extra={
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.alltransactions)
                    : CisUI().addAction('../' + jsonfile.urls.alltransactions)
            }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {/*{CisUI().showLoading}*/}

                {
                    noBillField
                }

            </Form>


        </Card>
    );
};

export default TransactionEdit;