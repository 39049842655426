import React,{useState} from "react";
import {Row, Col, Form, Card, Input, InputNumber, Button,  Select, notification, DatePicker} from 'antd';
import jsonfile from './employmentinfo.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import InputFieldV2 from "../../../../../util/InputFieldV2";
import {CisUI} from "../../../../../util/CISUI";
import Config from "../../../../../util/config";
import axios from "axios";
import IntlMessages from "../../../../../util/IntlMessages";
import StaticSelectData from "../../../../../util/StaticSelectData";
import SelectData from "../../../../../util/selectdata";

const {Option} = Select;
const {TextArea} = Input
const EmploymentInfo = (props) => {

    // console.log(props);

    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState([]);
    
    let over_time = StaticSelectData('over_time');
    let employment_status = StaticSelectData('employment_status');
    let department = SelectData('hrm/departments');
    let designation = SelectData('hrm/designation');
    let shift = SelectData('hrm/shift');

    const userID = props.empid;
    const editID = props.editEmpId;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(editID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add+"/"+userID;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+editID);
        console.log(userData);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+editID;
        redirectto = "../../"+jsonfile.urls.list;
    
        form.setFieldsValue({
            'over_time': userData.over_time,
            'employment_status': userData.employment_status,
            'department_id': userData.department_id,
            'designation_id': userData.designation_id,
            'ssn_no': userData.ssn_no,
            'govt_id': userData.govt_id,
            'salary': userData.salary,
            'shift_id': userData.shift_id,
            'start_date': userData.start_date,
            'notes': userData.notes,
            'working_history': userData.working_history
        });
    }

    const onFinish = (values) => {
        // console.log(values);
        let data = {
            ...values,
            'joining_date': values['joining_date'].format("YYYY-MM-DD")
        }
    
        // console.log(data);
    
        document.getElementById("loader").style.display = "block";

        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg)
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    }

    return (
        <Card  title={Title}>
                
            <Row gutter={24}>
                <Col lg={24}>
                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                    >
                        <div className="row">
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="over_time"
                                        label={<IntlMessages id="Over Time"/>}
                                        // initialValue={userData.podata.company_id}
                                        rules={[
                                            {
                                                required: false
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select an option"
                                            optionFilterProp="children"
                                            // onChange={onCompanyChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                over_time.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="employment_status"
                                        label={<IntlMessages id="Employment Status"/>}
                                        // initialValue={userData.podata.company_id}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Employment Status is required."
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select an option"
                                            optionFilterProp="children"
                                            // onChange={onCompanyChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                employment_status.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="department_id"
                                        label={<IntlMessages id="Department"/>}
                                        // initialValue={userData.podata.company_id}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Department is required."
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select an option"
                                            optionFilterProp="children"
                                            // onChange={onCompanyChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                department.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="designation_id"
                                        label={<IntlMessages id="Designation"/>}
                                        // initialValue={userData.podata.company_id}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Designation is required."
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select an option"
                                            optionFilterProp="children"
                                            // onChange={onCompanyChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                designation.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="ssn_no"
                                        label={<IntlMessages id="SSN NO."/>}
                                        // initialValue={userData.podata.company_id}
                                        rules={[
                                            {
                                                required: true,
                                                message: "SSN NO. is required."
                                            },
                                        ]}
                                    >
                                        <InputNumber className="gx-mb-3 gx-w-100" placeholder="Example: xxx-xx-xxxx"/>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="govt_id"
                                        label={<IntlMessages id="Govt. Issued ID Number"/>}
                                        // initialValue={userData.podata.company_id}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Govt. Issued ID Number is required."
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter Govt. Issued ID Number"/>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="salary"
                                        label={<IntlMessages id="Salary"/>}
                                        // initialValue={userData.podata.company_id}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Salary is required."
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter Salary"/>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="shift_id"
                                        label={<IntlMessages id="Shift"/>}
                                        // initialValue={userData.podata.company_id}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Shift is required."
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select an option"
                                            optionFilterProp="children"
                                            // onChange={onCompanyChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {
                                                shift.map((items, index) =>
                                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="joining_date"
                                        label={<IntlMessages id="Start Date"/>}
                                        // initialValue={userData.podata.company_id}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Start date is required."
                                            },
                                        ]}
                                    >
                                        <DatePicker className="gx-mb-3 gx-w-100" placeholder="YYYY-MM-DD"/>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="notes"
                                        label={<IntlMessages id="Notes"/>}
                                        // initialValue={userData.podata.company_id}
                                        rules={[
                                            {
                                                required: false
                                            },
                                        ]}
                                    >
                                        <TextArea placeholder="Enter Notes" rows={2}/>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="working_history"
                                        label={<IntlMessages id="Working History"/>}
                                        // initialValue={userData.podata.company_id}
                                        rules={[
                                            {
                                                required: false
                                            },
                                        ]}
                                    >
                                        <TextArea placeholder="Enter Working History" rows={2}/>
                                    </Form.Item>
                                </div>
                            </div>
                            
                        </div>
        
                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Save & Submit</Button>
                            </Col>
                        </Row>
                    </Form>
     
                </Col>
            </Row>
            
        </Card>
    );
}

export default EmploymentInfo;