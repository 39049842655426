import React from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";

const UserProfile = () => {
  const dispatch = useDispatch();

    const setHorizontalLayout = () => {
        console.log("Clicked on Window reload");
        localStorage.setItem("layout","horizontal");
        window.location.reload();
    }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>
         <Link to={`/profile/myaccount`}>My Account</Link>
      </li>
      <li>
          <Link to={`/profile/changepassword`}>Change Password</Link>
      </li>
      <li onClick={setHorizontalLayout}>Horizontal Layout</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

    const userLocal = localStorage.getItem('userData') || "";
    // let  userID = 0;
    let userName = "";
    if(userLocal !== "") {
        const user = JSON.parse(userLocal);
        // userID = user['id'];
        userName = user['name'];
    }

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={"https://via.placeholder.com/150"}
                className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
        <span className="gx-avatar-name">{userName}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>

  )
};

export default UserProfile;
