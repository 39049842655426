import React, {useState, useEffect} from "react";
import {Form, Card, Input, Select, Row, Col, Table, Popconfirm, Upload, Button} from 'antd';
import jsonfile from './documents.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import {CisUI} from "../../../../../util/CISUI";
import { UploadOutlined } from '@ant-design/icons';
import Config from "../../../../../util/config";
import axios from "axios";

const {Option} = Select;
const {TextArea} = Input
const DocumentInfo = (props) => {

    const history = useHistory();
    const {form} = Form.useForm();
    const [state, setState] = useState({
        rows: [
            {
                id: "",
                name: "",
                details: "",
                issue_date: "",
                expire_date: "",
                attachment: ""
            }
        ]
    });
    
    const [image, setImage] = useState("");
    const changeImage = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e)=> {
            setImage({
                image : e.target.result
            });
        };
        reader.readAsDataURL(file);
    }
    
    const userID = props.empid;
    const editID = props.editEmpId;
    let userData = [];

    let isEdit = 0;
    if (editID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add+"/"+userID;
    let redirectto = "../" + jsonfile.urls.list;
    
    let edit_data = [];
    
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+editID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+editID;
        redirectto = "../../"+jsonfile.urls.list;
        
        userData.forEach((item, index) =>
            edit_data.push({
                id: item.id,
                name: item.name,
                details: item.details,
                issue_date : item.issue_date,
                expire_date : item.expire_date,
                attachment : item.attachment
            })
        )
    }
    
    useEffect(() => {
        if(isEdit === 1){
            setState({
                rows: edit_data
            })
        }
    }, [userData]);
    
    const handleAdd = () => {
        // console.log("hi");
        const item = {
            id: 0,
            name: "",
            details: "",
            issue_date : "",
            expire_date : "",
            attachment : ""
        };
        setState({
            rows: [...state.rows, item]
        });
    };
    
    const handleRemoveRow = (idx) => e => {
        // console.log(idx);
        const filteredArray = [...state.rows];
        if(filteredArray.length == 1) {
            CisUI().Notification('error', 'You can not delete this item.')
        } else {
            setState({
                rows: filteredArray.filter((item, index) => item.id !== idx)
            });
            
            axios.delete(Config.apiserver + 'hrm/singlelanguageinfodelete/' + idx, CisUI().HeaderRequest)
                .then(res => {
                    // console.log(res.data)
                    if (res.data.status === 1) {
                        CisUI().Notification('success', res.data.msg)
                    } else {
                        CisUI().Notification('error', res.data.msg)
                    }
                })
                .catch(error => console.log(error))
        }
        
    };
    
    const handleAddChange = (id) => e => {
        
        const { name, value } = e.target;
        const rows = [...state.rows];
        // console.log("selectedITems:"+ JSON.stringify(rows[id]));
        
        if(name === "id") {
            rows[id] = {
                id: value,
                name: rows[id].name,
                details: rows[id].details,
                issue_date: rows[id].issue_date,
                expire_date: rows[id].expire_date,
                attachment: rows[id].attachment
            };
        }
        else if(name === "name") {
            rows[id] = {
                id: rows[id].id,
                name: value,
                details: rows[id].details,
                issue_date: rows[id].issue_date,
                expire_date: rows[id].expire_date,
                attachment: rows[id].attachment
            };
        }
        else if(name === "details") {
            rows[id] = {
                id: rows[id].id,
                name: rows[id].name,
                details: value,
                issue_date: rows[id].issue_date,
                expire_date: rows[id].expire_date,
                attachment: rows[id].attachment
            };
        }
        else if(name === "issue_date") {
            rows[id] = {
                id: rows[id].id,
                name: rows[id].name,
                details: rows[id].details,
                issue_date: value,
                expire_date: rows[id].expire_date,
                attachment: rows[id].attachment
            };
        }
        else if(name === "expire_date") {
            rows[id] = {
                id: rows[id].id,
                name: rows[id].name,
                details: rows[id].details,
                issue_date: rows[id].issue_date,
                expire_date: value,
                attachment: rows[id].attachment
            };
        }
        else if(name === "attachment") {
            rows[id] = {
                id: rows[id].id,
                name: rows[id].name,
                details: rows[id].details,
                issue_date: rows[id].issue_date,
                expire_date: rows[id].expire_date,
                attachment: value
            };
        }
        
        setState({
            rows
        });
        
    }
    
    
    const onFinish = (values) => {
        values["document_info"] = state.rows;
        console.log(values);
        document.getElementById("loader").style.display = "block";
        
        // axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
        //     .then(res => {
        //         // console.log(res.data)
        //         if(res.data.status === 1) {
        //             CisUI().Notification('success', res.data.msg)
        //         }
        //         else {
        //             CisUI().Notification('success', res.data.msg)
        //         }
        //         document.getElementById("loader").style.display = "none";
        //     })
        //     .catch(errors => {
        //         console.log(errors);
        //     });
    }


    return (
        <Card title={Title}>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            {/*<h2>Purchase Items</h2>*/}
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAdd}>Add More</a>
                        </div>
                    </div>

                    {/*<Table*/}
                    {/*    bordered*/}
                    {/*    className="gx-table-responsive"*/}
                    {/*    dataSource={data.dataSource}*/}
                    {/*    columns={columns}*/}
                    {/*    pagination={false}*/}
                    {/*/>*/}
    
                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                    >
                        <table id="docinfo" className="ptable table table-bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Details</th>
                                <th>Issue Date</th>
                                <th>Expire Date</th>
                                <th>Attachment</th>
                            </tr>
                            </thead>
                            <tbody>
                                {state.rows.map((item, idx) => (
                                        <tr id="addr0" key={idx}>
                                            <td style={{width : '30px'}}>
                                                <a onClick={handleRemoveRow(state.rows[idx].id)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
                                                {/*<Popconfirm title="Sure to delete?" onConfirm={handleRemoveRow(idx)}>*/}
                                                {/*    <a className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>*/}
                                                {/*</Popconfirm>*/}
                                            </td>
                                            <td style={{width : '150px'}}>
                                                <input type="hidden" className="form-control" name="number" value={state.rows[idx].id} onChange={handleAddChange(idx)} />
                                                <input type="text" className="form-control" placeholder="Name" name="name" value={state.rows[idx].name} onChange={handleAddChange(idx)} />
                                            </td>
                                            <td style={{width : '100px'}}>
                                                <textarea className="form-control" name="details" value={state.rows[idx].details} onChange={handleAddChange(idx)} />
                                            </td>
                                            <td style={{width : '100px'}}>
                                               <input type="date" className="form-control" name="issue_date" value={state.rows[idx].issue_date} onChange={handleAddChange(idx)} />
                                            </td>
                                            <td style={{width : '100px'}}>
                                                <input type="date" className="form-control" name="expire_date" value={state.rows[idx].expire_date} onChange={handleAddChange(idx)} />
                                            </td>
                                            <td style={{width : '100px'}}>
                                                <input type="file" className="form-control" name="attachment" value={state.rows[idx].attachment} onChange={handleAddChange(idx)} />
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
    
                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Save & Submit</Button>
                            </Col>
                        </Row>
                        
                    </Form>

                </div>
            </div>
        </Card>
    );
};

export default DocumentInfo;