import React from "react";
import {Route, Switch} from "react-router-dom";
import Main from "./main/index";
import Financial from "./financial/index";
import CRM from "./crm/index";
import Company from "./company/index";
import User from "./user/index";
import Donation from "./donation/index";
import Assets from "./assets/index";
import Project from "./project/index";
import Settings from "./settings/index";
import Vendors from "./vendors/index";
import HRM from "./hrm/index";
import Dashboard from "./dashboard/index";
import Profile from "./profile/index";

// import Dashboard from "./main/dashboard/CRM/index";
import Purchase from "./purchase/index";
import Document from "./document/index";

import ExportToWord from "../util/gendocs";
import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
    <div className="gx-main-content-wrapper">
        <Switch>
            <Route path={`${match.url}main`} component={Dashboard}/>
            <Route path={`${match.url}accounts`} component={Financial}/>
            <Route path={`${match.url}crm`} component={CRM}/>
            <Route path={`${match.url}company`} component={Company}/>
            <Route path={`${match.url}user`} component={User}/>
            <Route path={`${match.url}donation`} component={Donation}/>
            <Route path={`${match.url}assets`} component={Assets}/>
            <Route path={`${match.url}vendor`} component={Vendors}/>
            <Route path={`${match.url}hrm`} component={HRM}/>
            <Route path={`${match.url}purchase`} component={Purchase}/>
            <Route path={`${match.url}project`} component={Project}/>
            <Route path={`${match.url}settings`} component={Settings}/>
            <Route path={`${match.url}docs`} component={ExportToWord}/>
            <Route path={`${match.url}document`} component={Document}/>
            <Route path={`${match.url}profile`} component={Profile}/>
        </Switch>
    </div>
);

export default App;

