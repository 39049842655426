import React, {useState, useEffect} from "react";
import {Row, Col, Form, Card, Input, Select, notification, Button} from 'antd';
import jsonfile from './educationinfo.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import {CisUI} from "../../../../../util/CISUI";
import Config from "../../../../../util/config";
import axios from "axios";

const {Option} = Select;
const {TextArea} = Input
const EducationInfo = (props) => {

    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState([]);
    const userID = props.empid;
    const editID = props.editEmpId;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(editID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add+"/"+userID;
    let redirectto = "../"+jsonfile.urls.list;

    const [state, setState] = useState({
        rows: [
            {
                id:"",
                degree_name: "",
                institute_name: "",
                year : "",
                result : ""
            }
        ]
    });

    let edit_data = [];

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+editID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+editID;
        redirectto = "../../"+jsonfile.urls.list;

        userData.forEach((item, index) =>
            edit_data.push({
                degree_name: item.degree_name,
                institute_name: item.institute_name,
                year: item.year,
                result: item.result
            })
        )
    }

    useEffect(() => {
        if(isEdit === 1){
            setState({
                rows: edit_data
            })
        }
    }, [userData]);


    const handleAdd = () => {
        // console.log("hi");
        const item = {
            id: 0,
            degree_name: "",
            institute_name: "",
            year : "",
            result : ""
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    const handleRemoveRow = (idx) => e => {
        // console.log(idx);

        const filteredArray = [...state.rows];
        // console.log(filteredArray.length)
        if(filteredArray.length == 1) {
            CisUI().Notification('warning', 'You can not delete this item.')
        } else {
            setState({
                rows: filteredArray.filter((item, index) => item.id !== idx)
            });
            
            axios.delete(Config.apiserver+'hrm/singleeducationinfodelete'+idx, CisUI.HeaderRequest)
                .then(res => {
                    if (res.data.status === 1) {
                        CisUI().Notification('success', res.data.msg)
                    } else {
                        CisUI().Notification('error', res.data.msg)
                    }
                })
                .catch(error => console.log(error))
        }

    };

    const handleAddChange = (id) => e => {

        const { name, value } = e.target;
        const rows = [...state.rows];
        // console.log("selectedITems:"+ JSON.stringify(rows[id]));

        if(name === "id"){
            rows[id] = {
                id: value,
                degree_name: rows[id].degree_name,
                institute_name: rows[id].institute_name,
                year: rows[id].year,
                result: rows[id].result
            };
        }
        else if(name === "degree_name") {
            rows[id] = {
                id: rows[id].id,
                degree_name: value,
                institute_name: rows[id].institute_name,
                year: rows[id].year,
                result: rows[id].result
            };
        }
        else if(name === "institute_name") {
            rows[id] = {
                id: rows[id].id,
                degree_name: rows[id].degree_name,
                institute_name: value,
                year: rows[id].year,
                result: rows[id].result
            };
        }
        else if(name === "year") {
            rows[id] = {
                id: rows[id].id,
                degree_name: rows[id].degree_name,
                institute_name: rows[id].institute_name,
                year: value,
                result: rows[id].result
            };
        }
        else if(name === "result") {
            rows[id] = {
                id: rows[id].id,
                degree_name: rows[id].degree_name,
                institute_name: rows[id].institute_name,
                year: rows[id].year,
                result: value,
            };
        }

        setState({
            rows
        });

    }

    const onFinish = (values) => {
        values["education_info"] = state.rows;
        document.getElementById("loader").style.display = "block";

        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                console.log(res.data)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg)
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    }

    return (
        <Card title={Title}>
            {CisUI().showLoading}
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            {/*<h2>Purchase Items</h2>*/}
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAdd}>Add More</a>
                        </div>
                    </div>

                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                    >
                        <table id="educationinfo" className="ptable table table-bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Degree Name</th>
                                <th>Institute Name</th>
                                <th>Year</th>
                                <th>Result</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.rows.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                    <td style={{width : '30px'}}>
                                        <a onClick={handleRemoveRow(state.rows[idx].id)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
                                        {/*<Popconfirm title="Sure to delete?" onConfirm={handleRemoveRow(idx)}>*/}
                                        {/*    <a className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>*/}
                                        {/*</Popconfirm>*/}
                                    </td>
                                    <td style={{width : '150px'}}>
                                        <input type="hidden" className="form-control" name="number" value={state.rows[idx].id} onChange={handleAddChange(idx)} />
                                        <input type="text" className="form-control" placeholder="Degree Name" name="degree_name" value={state.rows[idx].degree_name} onChange={handleAddChange(idx)} />
                                    </td>
                                    <td style={{width : '100px'}}><input type="text" placeholder="Institute Name" className="form-control" name="institute_name" value={state.rows[idx].institute_name} onChange={handleAddChange(idx)} /></td>
                                    <td style={{width : '100px'}}><input type="text" placeholder="Year" className="form-control " name="year" value={state.rows[idx].year} onChange={handleAddChange(idx)} /></td>
                                    <td style={{width : '100px'}}><input type="text" placeholder="Result" className="form-control " name="result" value={state.rows[idx].result} onChange={handleAddChange(idx)} /></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Save & Submit</Button>
                            </Col>
                        </Row>

                    </Form>

                </div>
            </div>
        </Card>
    );
}

export default EducationInfo;