import React, {useEffect} from "react";
import {Button, Form, Input, Row, Col} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {userSignIn} from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";


const SignIn = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const authUser = useSelector(({auth}) => auth.authUser);

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
	};

	// for(var i=0, len=localStorage.length; i<len; i++) {
	//   var key = localStorage.key(i);
	//   var value = localStorage[key];
	//   console.log(key + " => " + value);
	// }


	const onFinish = values => {
		// console.log("finish",values)
		dispatch(userSignIn(values));
	};

	useEffect(() => {
		if (authUser !== null) {
			history.push('/');
		}
	}, [authUser]);

	return (
		<div className="gx-app-login-wrap">
			<div className="gx-app-login-container">
				<div className="gx-app-login-main-content">
					<div className="gx-app-logo-content">
						<div className="gx-app-logo-content-bg" style={{backgroundColor: '#d8d8d8 !important'}}>

						</div>
						{/*<div className="gx-app-logo-wid">*/}
						{/*<h1><IntlMessages id="app.userAuth.signIn"/></h1>*/}
						{/*<p><IntlMessages id="app.userAuth.bySigning"/></p>*/}
						{/*</div>*/}
						<div className="gx-app-logo">
							<img alt="example" src="/assets/images/coplogo.png"/>
							{/*<img alt="example" src="/assets/images/cislogo.png"/>*/}
						</div>
					</div>
					<div className="gx-app-login-content">
						<Form
							initialValues={{ remember: true }}
							name="basic"
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							className="gx-signin-form gx-form-row0">

							<Form.Item
								// initialValue="demo@example.com"
								rules={[{ required: true, message: 'The input is not valid E-mail!' }]} name="email">
								<Input placeholder="Userid"/>
							</Form.Item>
							<Form.Item
								// initialValue="demo#123"
								rules= {[{required: true, message: 'Please input your Password!'}]}  name="password">
								<Input type="password" placeholder="Password"/>
							</Form.Item>
							{/*<Row>*/}
							{/*	<Col span={9} offset={15}>*/}
									<Form.Item>
										<Button type="primary" className="gx-mb-0" htmlType="submit">
											<IntlMessages id="app.userAuth.signIn"/>
										</Button>
									</Form.Item>
								{/*</Col>*/}
								{/*<Col span={9} offset={15}>*/}
									<Link to="/forgotpassword"><IntlMessages id="app.userAuth.forgotPassword"/></Link>
								{/*</Col>*/}
							{/*</Row>*/}
						</Form>
					</div>
					<InfoView/>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
