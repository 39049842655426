import axios from 'axios';

const token = localStorage.getItem('token');

const axiosinstance = axios.create({

  // baseURL: "http://localhost:8000/api/",
  // baseURL: "https://sandboxapi.mycopusa.com/api/",
  // baseURL: "https://api.mycopusa.com/api/",
  baseURL: 'https://fmsapi.yottaerp.com/api/',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': token,
  }
});

export default axiosinstance;

// export const HeaderRequest = () => {
//   const token = localStorage.getItem('token');
//   return {headers : {Authorization : token}};
// }