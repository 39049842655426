import {Form, Row, Col, Input, Button, Card, Collapse, Modal, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './invoice.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import moment from "moment";
import 'moment/locale/es-us';

const {Option} = Select;
const {TextArea} = Input;

const InvoicePayment = (props) => {

    const formRef = React.createRef();
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const history = useHistory();

    const [title, setTitle] = useState(jsonfile.invoice_payment);
    const [hasPayment, setHasPayment] = useState(false);
    const [payments, setPayment] = useState([]);
    const [cashaccount, setCashAccount] = useState([]);
    const [isApprover, setApprover] = useState(false);
    const [hasBill, setHasbill] = useState(false);
    const [bill, setBill] = useState({});
    const [receivable, setReceivable] = useState([]);
    const [accounts, setAccounts] = useState([]);

    const [paymentmethod, setPaymentmethod] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModalCofirm = () => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
        BillStatusUpdate(bill.id,1);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showModalReject = () => {
        setIsRejectModalVisible(true);
    };

    const handleOkReject = () => {
        setIsRejectModalVisible(false);
        BillStatusUpdate(bill.id,3);
    };

    const handleCancelReject = () => {
        setIsRejectModalVisible(false);
    };

    const clickTOPrint = (id) => {
        const url = Config.apiserver+"vendor/checkprint?selected="+id;
        const ck = "../../checkprint.html?type=checkprint&url="+window.btoa(url)+"&selected="+id;
        //window.open(ck, "_blank");
        return ck;
    }

    const BillStatusUpdate = (id,status) => {
        document.getElementById("loader").style.display = "block";
        axios.get("customer/billstatusupdate?bill_id="+id+"&status="+status)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                console.log(res.data);
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    getPODetails();
                }
                else {
                    document.getElementById("loader").style.display = "none";
                    notification.success({
                        message: 'Failed',
                        type: "danger",
                        description: res.data.msg
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState([]);
    const [polist, setPolist] = useState({
        po_id : 0,
        po_number : "",
        date: moment(),
        due_date: moment(),
        reference_no: "",
        company: "",
        company_id: 0,
        vendor_id: 0,
        vendor: "",
        payable_id: null,
        fund_program_id : null,
        func_expense_id : null,
        amount : 0,
        description : "",
        remark : "",
        status : 0,
        approved_by : "",
        approved_at : "",
    });



    const poid = props.match.params.id;
    const userID = poid;
    const redirectto = "../../"+jsonfile.urls.list;
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;

    }


    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver + "crm/invoicedetails/" + poid)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                //console.log(res.data);
                if (res.data.status === 1) {
                    setBill(res.data.podata);
                    setItems(res.data.items);
                    if(res.data.hasPayment === 1) {
                        setHasPayment(true);
                    }
                    setCashAccount(res.data.cashAccount);
                    setPayment(res.data.payment);
                    setBankAccounts(res.data.bankaccount);
                    setPaymentmethod(res.data.payment_method);
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });

    }

    const onPaymentMethodChange = (value) => {
        console.log("mehtod" + value);
        if(value === 2) {
            document.getElementById("bankaccount").style.display = "block";
        }
        else {
            document.getElementById("bankaccount").style.display = "none";
        }
        formRef.current.setFieldsValue({
            bank_account_id : null,
            reference : ""
        });
    }

    const ApproveOrReject = (
        <>
            <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                {
                    bill.status === 0 ?
                        isApprover === true
                            ? <>
                                <Button id="submitbutton" type="danger" onClick={showModalReject} htmlType="button">Reject</Button>
                                <Button id="submitbutton" type="primary" onClick={showModalCofirm} htmlType="button">Approve</Button>
                            </>
                            : ""
                        : ""
                }
                {
                    // bill.status === 1 ?
                    //     hasPayment === false ?
                    //         <><Button id="submitbutton" type="primary" onClick={showModalReject} htmlType="button"><Link to={"../newpayment?bill_id="+bill.id}>Payment</Link></Button></>
                    //         : ""
                    //     : ""
                }
                {/*<Button type="info" onClick={() => history.goBack()} htmlType="back">Back</Button>*/}
            </div>
        </>
    );

    const noBillField = (
        <>
            {
                bill.status === 1 && (bill.total - bill.paid_amount > 0) ?
                    <>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Item
                                    name="date"
                                    label="Date"
                                    initialValue={moment()}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select a date"
                                        },
                                    ]}
                                >
                                    <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                                placeholder="Date"/>
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    name="due_date"
                                    label="Due Date"
                                    initialValue={moment()}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select a due date"
                                        },
                                    ]}
                                >
                                    <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                                placeholder="Due Date"/>
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    name="payment_method"
                                    label="Payment Method"
                                    initialValue={1}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select payment method"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a payable account"
                                        optionFilterProp="children"
                                        onChange={onPaymentMethodChange}
                                        allowClear={true}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            paymentmethod.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-md-4" id="bankaccount" style={{display: 'none'}}>
                                <Form.Item
                                    name="bank_account_id"
                                    label="Bank Account"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Select a bank account"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a bank account"
                                        optionFilterProp="children"
                                        allowClear={true}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            bankaccounts.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.account_no}-{items.account_name}({items.bank_name})</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    name="account_id"
                                    label="Account"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select an account"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select an account"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            cashaccount.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    name="reference_no"
                                    label="Reference#"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Enter reference number"
                                        },
                                    ]}
                                >
                                    <Input className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                                </Form.Item>
                            </div>


                            <div className="col-md-4">
                                <Form.Item
                                    name="amount"
                                    label="Amount"
                                    initialValue={(bill.total - bill.paid_amount)}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter amount here"
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter amount ehre"/>
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item
                                    name="description"
                                    label="Description"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter description here"
                                        },
                                    ]}
                                >
                                    <TextArea placeholder="Enter description here"/>
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item
                                    name="remark"
                                    label="Remark/Memo"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Enter something here"
                                        },
                                    ]}
                                >
                                    <TextArea placeholder="Enter somthing ehre"/>
                                </Form.Item>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                                <Button id="submitbutton" type="primary" htmlType="submit">
                                    Save Payment
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                            </div>
                        </div>
                    </>
                    : ""
            }
        </>
    );

    let totalPaid = 0;

    const BillInfo = (
        <div className="row" style={{padding: '20px'}}>
            <h4>Invoice Details</h4>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Invoice Number : </label>
                <span> {bill.invoice_number}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Customer : </label>
                <span> {bill.customer_name}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Customer Phone : </label>
                <span> {bill.customer_phone}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Date : </label>
                <span> {CisUI().DateFormat(bill.date)}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Invoiced Amount : </label>
                <span style={{color: 'green'}}> {CisUI().getCurrencyFormated1(bill.total)}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Paid Amount : </label>
                <span style={{color: 'green'}}> {CisUI().getCurrencyFormated1(bill.paid_amount)}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Due Amount : </label>
                <span style={{color: 'green'}}> {CisUI().getCurrencyFormated1(bill.total - bill.paid_amount)}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Account Receivable : </label>
                <span> {bill.receiveable}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Account : </label>
                <span> {bill.accounts}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Created By : </label>
                <span> {bill.created_by}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Created at : </label>
                <span> {bill.created_at}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Invoice Status : </label>
                <span> {CisUI().getBillSatus(bill.status)}</span>
            </div>

            {
                hasPayment ?
                    <>
                        <h4 style={{marginTop: '20px'}}>Payment Information</h4>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Title</th>
                                    <th>Account</th>
                                    <th>Payment Method</th>
                                    <th>Bank Account</th>
                                    <th>Reference/Check No.</th>
                                    <th style={{textAlign: 'right'}}>Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    payments.map((list, index) => {
                                        totalPaid += parseFloat(list.debit);
                                        return <tr>
                                            <td>{CisUI().DateFormat(list.date)}</td>
                                            <td>{list.title}</td>
                                            <td>{list.account_head}</td>
                                            <td>{list.payment_method}</td>
                                            <td>{list.bank_account}</td>
                                            <td>{list.reference}</td>
                                            <td style={{textAlign: 'right'}}>{list.debit}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th colSpan={6}>Total Paid</th>
                                    <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(totalPaid)}</th>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </>
                    : ""
            }

            {ApproveOrReject}


        </div>
    );

    const onFinish = (values) => {
        setLoading({loading:true})
        values["invoice_id"] = bill.id;
        console.log("Input Data : "+values);
        // console.log(values);
        let msg = "Sorry! request not processed, please try again";
        const remain = bill.total - bill.paid_amount;
        if(values['amount'] <= remain) {
            axios.post(Config.apiserver + "crm/payment/"+bill.id, values)
                .then(res => {
                    console.log(res)
                    if (res.data.status === 1) {
                        notification.success({
                            message: 'Success',
                            type: "success",
                            description: res.data.msg
                        });
                        history.push(redirectto);
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                    }
                    setLoading({loading: false});
                })
                .catch(errors => {
                    console.log(errors.response.data.errors);
                    // msg = (errors);
                    setLoading({loading: false});
                    //setResponse([]);
                });
        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Entering amount should not be more than due amount"
            });
        }
    }

    return (
        <Card title={title}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.list)
                    : CisUI().addAction('../' + jsonfile.urls.list)
            }

            {CisUI().showLoading}

            {BillInfo}


            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
             {noBillField}
            </Form>


            <Modal title="Alert" visible={isConfirmModalVisible} okText={"Confirm"} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
                <h4>Are you sure you want to approve ?</h4>
            </Modal>

            <Modal title="Alert" visible={isRejectModalVisible} okText={"Confirm"} onOk={handleOkReject} onCancel={handleCancelReject}>
                <h4>Are you sure you want to reject ?</h4>
            </Modal>

        </Card>
    );
};

export default InvoicePayment;