import React from "react";
import {Route, Switch} from "react-router-dom";

import PurachaseList from "../../containers/yotta/purchase/list";
import PurachaseListPeinding from "../../containers/yotta/purchase/pendinglist";
import NewPurchase from "../../containers/yotta/purchase/new";
import EditPurchase from "../../containers/yotta/purchase/edit_purchase";
import OrderDetails from "../../containers/yotta/purchase/details";
import PrintDetails from "../../containers/yotta/purchase/print";

const Purchase = ({match}) => (
    <Switch>
        <Route path={`${match.url}/newpoorder`} component={NewPurchase}/>
        <Route path={`${match.url}/poorders`} component={PurachaseList}/>
        <Route path={`${match.url}/pendingpo`} component={PurachaseListPeinding}/>
        <Route path={`${match.url}/podetails/:id`} component={OrderDetails}/>
        <Route path={`${match.url}/poedit/:id`} component={EditPurchase}/>
        <Route path={`${match.url}/print/:id`} component={PrintDetails}/>

    </Switch>
);

export default Purchase;