import React, {useEffect, useState} from "react";
import {Form, Col, Input, Card, Collapse, notification} from 'antd';
import jsonfile from './billpayment.json';
import {useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";

const Search = Input.Search;
const Panel = Collapse.Panel;
const Meta = Card.Meta;

const BillPaymentHistory = () => {
    const value = 0;
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : ''
    });

    const [printurl,setPrinturl] = useState([]);
    const [stdata,setStdata] = useState([]);
    const [selected,setSelected] = useState([]);

    const selectedForPrint = (e) => {
        // console.log(JSON.stringify(selected));
        // const st = [...selected];
        // console.log(e.target.checked);
        if(e.target.checked === true) {
            //st[e.target.name] = e.target.value;
            selected.push(e.target.value);
        }
        else {
            selected.pop(e.target.value);
        }
        // setSelected(st);
       // console.log(JSON.stringify(selected));
       // console.log(JSON.stringify(selected.length));

        if(selected.length > 0) {
            document.getElementById("prntbtn").style.display = "block";
            document.getElementById("printedbtn").style.display = "block";
            setStdata(Object.values(selected).join(','));
        }
        else {
            document.getElementById("prntbtn").style.display = "none";
            document.getElementById("printedbtn").style.display = "none";
            setStdata("");
        }
    }

    const clickTOPrint = (id) => {
        const url = Config.apiserver+"vendor/checkprint?selected="+id;
        const ck = "../../checkprint.html?type=checkprint&url="+window.btoa(url)+"&selected="+id;

        //window.open(ck, "_blank");
        return ck;
    }

    const checkAll = (e) => {

        var ele=document.getElementsByClassName('check_all');

        for(var i=0; i<ele.length; i++){

            if(ele[i].checked === false) {
                ele[i].checked = true;
                selected.push(parseInt(ele[i].value))
            } else {
                ele[i].checked = false;
                selected.pop(parseInt(ele[i].value))
            }
            // ele[i].checked = ele[i].checked === false;

            if(selected.length > 0) {
                document.getElementById("prntbtn").style.display = "block";
                document.getElementById("printedbtn").style.display = "block";
                setStdata(Object.values(selected).join(','));
            }
            else {
                document.getElementById("prntbtn").style.display = "none";
                document.getElementById("printedbtn").style.display = "none";
                setStdata("");
            }
        }
    }


    const headers = {
        id: {
            text: <input type="checkbox" onClick={checkAll} id="select_all" style={{marginRight: '10px'}}/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                // <input type="checkbox" onChange={selectedForPrint} name={"ck_"+value} value={value} className="check_all" checked={selected.includes(value)}/>
                <input type="checkbox" onChange={selectedForPrint} name={"ck_"+value} value={value} className="check_all" />
            )
        },
        bills: {
            invisible: true
        },
        allbills: {
            invisible: true
        },
        company_id: {
            invisible: true
        },
        user_id: {
            invisible: true
        },
        vendor_id: {
            invisible: true
        },
        bank_account_id: {
            invisible: true
        },
        updated_at: {
            invisible: true
        },
        print_time: {
            invisible: true
        },
        is_print: {
            invisible: true
        },
        printed_by: {
            invisible: true
        },
        status: {
            invisible: true
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(row.date)
            )
        },
        amount: {
            text: <IntlMessages id="Amount"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(row.amount)
            )
        },
        actions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    <a href={clickTOPrint(row.id)} target="_blank" className="btn btn-primary btn-xs">Print</a>
                </>
            ),
        },
    };

    axios.get(Config.apiserver + "vendor/paymenthistory","",CisUI().HeaderRequest)
        .then((res) => {
            console.log(res.data);
        })
        .catch((error) => {
            console.log(error);
        });

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const data = {
            ...values,
            // 'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
        }
        // console.log(data);
        const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    // const filterValue = "";

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1,"date",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const goForPrint = () => {
        const url = Config.apiserver + "vendor/checkprint?selected=" + stdata;
        window.open("../../checkprint.html?type=checkprint&url=" + window.btoa(url) + "&selected=" + stdata, "_blank");
    }

    const changePrintStatus = () => {
        const url = Config.apiserver + "vendor/pendingcheckprintstatus?selected=" + stdata;
        axios.post(url)
            .then(res => {
                if(res.data.status === 1){
                    // history.push(Config.apiserver+jsonfile.urls.list);
                    // const newURL = Config.apiserver+jsonfile.urls.list;
                    // setState({reqURL: newURL});
                    // setState({
                    //     reqURL: Config.apiserver+jsonfile.urls.list
                    // })
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    window.location.reload();
                }
                else {
                    notification.error({
                        message: 'Error',
                        type : "error",
                        description: res.data.msg
                    });
                }
            })
    }

    const getExtra = (
        <>
            {CisUI().listAction("../"+jsonfile.urls.add,"",state.reqURL,"data","pendingcheck",jsonfile)}
        </>
    );



    return (
        <>
            {searchView}
            <Card title={jsonfile.listtitle} extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <div className="row" style={{padding: '10px'}}>
                    {/*<span style={{width: '120px', marginTop: '12px'}}>*/}
                    {/*    <input type="checkbox" onClick={checkAll} id="select_all" style={{marginRight: '10px'}}/>Select All*/}
                    {/*</span>*/}
                    <a id="prntbtn" onClick={goForPrint} className="btn btn-primary" style={{width: '100px', display: 'none'}} target="_blank">Print Check</a>
                    <a id="printedbtn" onClick={changePrintStatus} className="btn btn-primary" style={{width: '100px', display: 'none'}} target="_blank">Set Printed</a>
                </div>
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default BillPaymentHistory;