import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button, Collapse ,Card, Table, Radio, notification} from 'antd';
import jsonfile from './banktransaction.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";

const Search = Input.Search;
const Panel = Collapse.Panel;

let  searchView = "";

const BankReconcileStart = () => {
    const value = 0;

    const history = useHistory();

    const [form] = Form.useForm();

    const [state, setState] = useState({
        balance : 0,
        ending_balance : 0,
        transaction : [],
        receipt : [],
        receiptAmount : 0,
        payment : [],
        paymentAmount : 0,
        title : "Reconciliation on -",
        account : {},
        uinputs: {},
        trans: [],
        haslater : 0,
        trxid : ""
    });

    const [loading,setLoading] = useState(false);
    const [local,setLocal] = useState({});
    const [difference,setDifference] = useState(0);
    const [selected,setSelected] = useState([]);
    const [selectedck,setSelectedCK] = useState([]);
    const [panel,setPanel] = useState(
        { vl : 'all' }
    );

    useEffect(() => {
        getReconcileData();
    }, []);


    const getReconcileData = () => {
        const data1 = localStorage.getItem("reconcile") || "";
        if(data1 !== "") {
            const data = JSON.parse(data1);
            setLocal(data);
            document.getElementById("loader").style.display = "block";
            if (data.account_id > 0) {
                let com_id = data.com_id;
                const date = data.month;
                const account_id = data.account_id;
                const balance = parseFloat(data.balance);
                const ending_balance = parseFloat(data.ending_balance) || 0;

                if (com_id > 0 && date !== '' && account_id > 0) {
                    axios.get(Config.apiserver + "getreconciledata?com_id=" + com_id + "&account_id=" + account_id + "&date=" + date)
                        .then(res => {
                            //console.log(res)
                            if (res.data.status === 1) {
                                const balance = res.data.balance;
                                const account = res.data.account;
                                const trx = res.data.transaction;
                                const receipt = trx.filter(item => item.debit > 0);
                                const payment = trx.filter(item => item.credit > 0);
                                let receiptAmount = 0;
                                let paymentAmount = 0;
                                let selectedItem = [];
                                let selectedItemCkd = [];
                                let trxselected = res.data.transaction;
                                const trns = res.data.trans;

                                if(res.data.haslater === 1) {
                                    let allSelection = [];
                                    trns.map((itm,indx)=>{
                                        allSelection.push(itm.id);
                                    });
                                    setSelected(allSelection);

                                    //console.log("selection : "+allSelection);

                                    let receiptA = 0;
                                    let paymentA = 0;
                                    allSelection.map((value,indx) => {
                                        trx.map((itm,ind) => {
                                            if(itm.id === value) {
                                                receiptA += itm.debit;
                                                paymentA += itm.credit;
                                            }
                                        });
                                    });


                                    //console.log(receipt);
                                    //let gap = receipt - payment;
                                    const InterestEarn = parseFloat(data.interest_charge) || 0;
                                    const InterestCharge = parseFloat(data.service_charge) || 0;
                                    const difference1 = (parseFloat(balance) + parseFloat(receiptA)) - (parseFloat(ending_balance) + parseFloat(paymentA));
                                    const difference = difference1 + (parseFloat(InterestEarn) - parseFloat(InterestCharge));


                                    setState({
                                        balance: balance,
                                        ending_balance: ending_balance,
                                        transaction: trxselected,
                                        receipt: receipt,
                                        receiptAmount: receiptA + InterestEarn,
                                        payment: payment,
                                        paymentAmount: paymentA + InterestCharge,
                                        title: "Reconcile on - " + account.account_number + "-" + account.account_name,
                                        account: account,
                                        uinputs: res.data.uinputs,
                                        trans: res.data.trans,
                                        trxid: res.data.trxid,
                                        haslater : res.data.haslater,
                                    });

                                    setDifference(difference);


                                }
                                else {
                                    trx.map((item, index) => {
                                        if (item.debit > 0) {
                                            receiptAmount += parseFloat(item.debit);
                                            selectedItemCkd.push({
                                                id: item.id,
                                                isChecked: true,
                                                amount: item.debit
                                            });
                                        } else if (item.credit > 0) {
                                            paymentAmount += parseFloat(item.credit);
                                            selectedItemCkd.push({
                                                id: item.id,
                                                isChecked: true,
                                                amount: -item.credit
                                            });
                                        }
                                        selectedItem.push(item.id);
                                    });

                                    setSelected(selectedItem);
                                    setSelectedCK(selectedItemCkd);

                                    const InterestEarn = data.interest_charge || 0;
                                    const InterestCharge = data.service_charge || 0;
                                    //console.log("InterestEarn : "+ InterestEarn);
                                    //console.log("InterestExpense : "+ InterestCharge);
                                    // console.log("InterestExpense : "+ InterestCharge);
                                    const difference1 = (parseFloat(balance) + parseFloat(receiptAmount)) - (parseFloat(paymentAmount) + parseFloat(ending_balance));
                                    //console.log("dif1: "+difference1);
                                    const difference = difference1 + (parseFloat(InterestEarn) - parseFloat(InterestCharge));
                                    //console.log("dif2: "+difference);
                                    setDifference(difference);

                                    //console.log(trns);

                                    setState({
                                        balance: balance,
                                        ending_balance: ending_balance,
                                        transaction: trxselected,
                                        receipt: receipt,
                                        receiptAmount: receiptAmount + InterestEarn,
                                        payment: payment,
                                        paymentAmount: paymentAmount + InterestCharge,
                                        title: "Reconcile on - " + account.account_number + "-" + account.account_name,
                                        account: account,
                                        uinputs: res.data.uinputs,
                                        trans: res.data.trans,
                                        trxid: res.data.trxid,
                                        haslater : res.data.haslater,
                                    });
                                }


                            } else {
                                notification.warning({
                                    message: 'Alert',
                                    type: "warning",
                                    description: res.data.msg
                                });
                            }
                            document.getElementById("loader").style.display = "none";
                        })
                        .catch(errors => {
                           // console.log(errors.response.data.errors);
                            document.getElementById("loader").style.display = "none";
                        });
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: "Please select a company and month"
                    });
                    document.getElementById("loader").style.display = "none";
                }
            } else {
                notification.warning({
                    message: 'Alert',
                    type: "warning",
                    description: "No reconcilation information found, please start from beginning"
                });
                document.getElementById("loader").style.display = "none";
                history.push("bankreconcile");
            }
        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "No reconcilation information found, please start from beginning"
            });
            document.getElementById("loader").style.display = "none";
            history.push("bankreconcile");
        }
    }

    const onFinish = values => {
        console.log('Received values of form: ', values);
        const qsup = CisUI().getQueryStrings(values);
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.alltransactions+"/?type=2&"+qsup;
        setState({reqURL: newURL});
    };


    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    const setSelection = (e) => {

        let slct = selected;
        const vl = parseInt(e.target.value);
        if(e.target.checked) {
            if(selected.some(item => item === vl)) {

            }
            else {
                slct.push(vl);
            }
        }
        else {
            if(selected.some(item => item === vl)) {
                var index = slct.indexOf(vl); // Let's say it's Bob.
                slct.splice(index,1);
            }
        }
        setSelected(slct);
        //console.log(slct);

        let receipt = 0;
        let payment = 0;
        let receiptA = [];
        let paymentA = [];
        slct.map((value,indx) => {
            state.transaction.map((itm,ind) => {
                if(itm.id === value) {
                    receipt += itm.debit;
                    payment += itm.credit;

                    if(itm.debit > 0) {
                        receiptA.push(itm);
                    }
                    else if(itm.credit > 0) {
                        paymentA.push(itm);
                    }

                }
            });
        });

        //console.log(receipt);
        //let gap = receipt - payment;
        const InterestEarn = parseFloat(local.interest_charge) || 0;
        const InterestCharge = parseFloat(local.service_charge) || 0;
        const difference1 = (parseFloat(state.balance) + parseFloat(receipt)) - (parseFloat(state.ending_balance) + parseFloat(payment));

        const difference = difference1 + (parseFloat(InterestEarn) - parseFloat(InterestCharge));
        state.receiptAmount = receipt + InterestEarn ;
        state.receipt = receiptA;
        state.paymentAmount = payment + InterestCharge;
        state.payment = paymentA;
        setDifference(difference);

    }

    const ReconcileNow = () => {
        if(difference === 0) {
            let values = {};
            values["inputed"] = local;
            values["selected"] = selected.join(",");

            console.log("Values : ",values);
            document.getElementById("loader").style.display = "block";
            axios.post(Config.apiserver + jsonfile.urls.newbankreconcilation, values)
                .then(res => {
                    console.log(res)
                    document.getElementById("loader").style.display = "none";
                    if (res.data.status === 1) {
                        notification.success({
                            message: 'Success',
                            type: "success",
                            description: res.data.msg
                        });
                        //history.push(redirectto);
                        localStorage.removeItem("reconcile");
                        history.push("../"+jsonfile.urls.reconciliationhistory);
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                    }
                    setLoading({loading: false});
                })
                .catch(errors => {
                    console.log(errors.response.data.errors);
                    setLoading({loading: false});
                    document.getElementById("loader").style.display = "none";
                });

        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Differences amount should be 0 to continue with reconciliation"
            });
        }
    }


    const ReconcileLater = () => {
        //localStorage.removeItem("reconcile");
        //history.goBack();

        let values = {};
        values["inputed"] = local;
        values["selected"] = selected.join(",");

        console.log("Values : ",values);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + jsonfile.urls.newbankreconcilationlater, values)
            .then(res => {
                console.log(res)
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    //history.push(redirectto);
                    localStorage.removeItem("reconcile");
                    history.push("../"+jsonfile.urls.reconciliationhistory);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
                document.getElementById("loader").style.display = "none";
            });

    }

    const onPanelChange = (e) => {
        setPanel({vl: e.target.value})
    }

    const CheckAll = (e) => {
        let slctd = selected;
        if(e.target.checked) {
            if(panel.vl === 'all') {
                let slct = [];
                state.transaction.map((items,index)=> {
                    slct.push(items.id);
                });
                setSelected(slct);
                slctd = slct;
            }
            else if(panel.vl === 'deposit') {
                let slct = selected;
                state.receipt.map((items,index)=> {
                    if(selected.some(item => item === items.id)) {

                    }
                    else {
                        slct.push(items.id);
                    }
                });
                slctd = slct;
                setSelected(slct);
            }
            else if(panel.vl === 'payment') {
                let slct = selected;
                state.payment.map((items,index)=> {
                    if(selected.some(item => item === items.id)) {

                    }
                    else {
                        slct.push(items.id);
                    }
                });
                slctd = slct;
                setSelected(slct);
            }
        }
        else {
            if(panel.vl === 'all') {
                let slct = [];
                setSelected(slct);
                slctd = slct;
            }
            else if(panel.vl === 'deposit') {
                let slct = selected;
                state.receipt.map((items,index)=> {
                    if(selected.some(item => item === items.id)) {
                        var index = slct.indexOf(items.id);
                        slct.splice(index,1);
                    }
                });
                slctd = slct;
                setSelected(slct);
            }
            else if(panel.vl === 'payment') {
                let slct = selected;
                state.payment.map((items,index)=> {
                    if(selected.some(item => item === items.id)) {
                        var index = slct.indexOf(items.id);
                        slct.splice(index,1);
                    }
                });
                slctd = slct;
                setSelected(slct);
            }
        }

        let receipt = 0;
        let payment = 0;
        let receiptA = [];
        let paymentA = [];
        slctd.map((value,indx) => {
            state.transaction.map((itm,ind) => {
                if(itm.id === value) {
                    receipt += itm.debit;
                    payment += itm.credit;

                    if(itm.debit > 0) {
                        receiptA.push(itm);
                    }
                    else if(itm.credit > 0) {
                        paymentA.push(itm);
                    }
                }
            });
        });

        //console.log("payment : ",(parseFloat(state.ending_balance) + parseFloat(payment)));
        const InterestEarn = parseFloat(local.interest_charge) || 0;
        const InterestCharge = parseFloat(local.service_charge) || 0;
        const difference1 = (parseFloat(state.balance) + parseFloat(receipt)) - (parseFloat(state.ending_balance) + parseFloat(payment));
        const difference = difference1 + (parseFloat(InterestEarn) - parseFloat(InterestCharge));
        state.receiptAmount = receipt + InterestEarn;
        state.receipt = receiptA;
        state.paymentAmount = payment + InterestCharge;
        state.payment = paymentA;
        setDifference(difference);

    }

    return (
        <>
            <Card title={state.title}>
                {CisUI().showLoading}
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Month</th>
                                    <th>Beginning Balance</th>
                                    <th>Ending Balance</th>
                                    <th>Number of Transaction</th>
                                    <th>Receipts</th>
                                    <th>Payment</th>
                                    <th>Differences</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{CisUI().getMonthTitle(local.month)}</td>
                                    <td>{CisUI().getCurrencyFormated1(local.balance)}</td>
                                    <td>{CisUI().getCurrencyFormated1(local.ending_balance)}</td>
                                    <td>{state.transaction.length}</td>
                                    <td>
                                        {CisUI().getCurrencyFormated1(state.receiptAmount)}
                                        <br /> Items :  {state.receipt.length}
                                    </td>
                                    <td>
                                        {CisUI().getCurrencyFormated1(state.paymentAmount)}
                                        <br /> Items : {state.payment.length}
                                    </td>
                                    <td style={{fontWeight : 'bold', fontSize : '20px'}}>{CisUI().getCurrencyFormated1(difference)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="row" style={{marginBottom : '20px'}}>
                            <h4>New Income & Charges</h4>
                            <div className="col-md-4">
                                <h5>Interest Charge</h5>
                                <div><strong>Amount : </strong> {CisUI().getCurrencyFormated1(local.service_charge)} </div>
                                <div><strong>Account : </strong>{local.expenseAct || ""}</div>
                                <div><strong>Fund Program : </strong>{local.fundAct || ""}</div>
                            </div>
                            <div className="col-md-4">
                                <h5>Interest Earned</h5>
                                <div><strong>Amount : </strong> {CisUI().getCurrencyFormated1(local.interest_charge)} </div>
                                <div><strong>Account : </strong> {local.incomeAct || ""} </div>
                                <div><strong>Functional Expense : </strong>{local.fexpenseAct || ""}</div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6">
                        <h4>Transaction Items</h4>
                        <Radio.Group value={panel.vl} onChange={onPanelChange} style={{ marginBottom: 16 }}>
                            <Radio.Button value="all">All</Radio.Button>
                            <Radio.Button value="deposit">Deposit</Radio.Button>
                            <Radio.Button value="payment">Payment</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className="col-md-6" style={{textAlign : 'right'}}>
                        {/*<Button type="info" onClick={() => history.goBack()} htmlType="back">Back</Button>*/}
                        <Button id="rl" type="info" onClick={ReconcileLater} htmlType="button">Reconcile Later</Button>
                        <Button id="rn" type="primary" onClick={ReconcileNow} htmlType="button">Reconcile Now</Button>
                    </div>

                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Reference/Check No.</th>
                                    <th>Payee/Beneficiary</th>
                                    <th>Memo</th>
                                    <th style={{textAlign : 'right'}}>Deposit</th>
                                    <th style={{textAlign : 'right'}}>Payment</th>
                                    <th>
                                        <input type="checkbox" id="ckall" onChange={CheckAll} />
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    panel.vl === 'all' ?
                                    state.transaction.map((items,index) => {
                                        return <tr>
                                            <td>{CisUI().DateFormat(items.date)}</td>
                                            <td>{items.source}</td>
                                            <td>{items.reference}</td>
                                            <td>{
                                                items.bt === 1 ? items.payee_member : items.bt === 2 ? items.payee_customer : items.bt === 3 ? items.payee_vendor : items.bt === 4 ? items.payee_emp : ""
                                            }</td>
                                            <td>{items.title}</td>
                                            <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.debit)}</td>
                                            <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.credit)}</td>
                                            <td>
                                                <input key={index} checked={selected.indexOf(items.id) > -1} type="checkbox" id={"trx_"+items.id} name="trx" value={items.id} onChange={setSelection}  />
                                            </td>
                                        </tr>
                                    })
                                    : panel.vl === 'deposit' ?
                                        state.receipt.map((items,index) => {
                                            return <tr>
                                                <td>{CisUI().DateFormat(items.date)}</td>
                                                <td>{items.source}</td>
                                                <td>{items.reference}</td>
                                                <td>{
                                                    items.bt === 1 ? items.payee_member : items.bt === 2 ? items.payee_customer : items.bt === 3 ? items.payee_vendor : items.bt === 4 ? items.payee_emp : ""
                                                }</td>
                                                <td>{items.title}</td>
                                                <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.debit)}</td>
                                                <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.credit)}</td>
                                                <td>
                                                    <input key={index} type="checkbox" checked={selected.indexOf(items.id) > -1} id={"trx_"+items.id} name="trx" value={items.id} onChange={setSelection}  />
                                                </td>
                                            </tr>
                                        })
                                        : panel.vl === 'payment' ?
                                            state.payment.map((items,index) => {
                                                return <tr>
                                                    <td>{CisUI().DateFormat(items.date)}</td>
                                                    <td>{items.source}</td>
                                                    <td>{items.reference}</td>
                                                    <td>{
                                                        items.bt === 1 ? items.payee_member : items.bt === 2 ? items.payee_customer : items.bt === 3 ? items.payee_vendor : items.bt === 4 ? items.payee_emp : ""
                                                    }</td>
                                                    <td>{items.title}</td>
                                                    <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.debit)}</td>
                                                    <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(items.credit)}</td>
                                                    <td>
                                                        <input key={index} checked={selected.indexOf(items.id) > -1} type="checkbox" id={"trx_"+items.id} name="trx" value={items.id} onChange={setSelection}  />
                                                    </td>
                                                </tr>
                                            })
                                            : ""

                                }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </Card>
        </>
    );
};

export default BankReconcileStart;