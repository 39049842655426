import React, {useState} from "react";
import {Row, Col, Form, Card, Input, notification, Button} from 'antd';
import jsonfile from './beneficiaryinfo.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import {CisUI} from "../../../../../util/CISUI";
import Config from "../../../../../util/config";
import axios from "axios";
import InputFieldV2 from "../../../../../util/InputFieldV2";
import IntlMessages from "../../../../../util/IntlMessages";

const {TextArea} = Input
const BeneficiaryInfo = (props) => {

    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState([]);
    const userID = props.empid;
    const editID = props.editEmpId
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(editID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add+"/"+userID;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+editID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+editID;
        redirectto = "../../"+jsonfile.urls.list;
        
        form.setFieldsValue({
            name: userData.name,
            age: userData.age,
            identity_no: userData.identity_no,
            relation: userData.relation,
            phone: userData.phone,
            address: userData.address,
        });
    }

    const onFinish = (values) => {
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg)
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    }

    return (
        <Card title={Title}>
    
            <Row gutter={24}>
                <Col lg={24}>
                    {CisUI().showLoading}
                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                    >
                        <div className="row">
                            
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="name"
                                        label={<IntlMessages id="Name"/>}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Name is required."
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter Name"/>
                                    </Form.Item>
                                </div>
                            </div>
                    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="age"
                                        label={<IntlMessages id="Age"/>}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Age is required."
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter Age"/>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="identity_no"
                                        label={<IntlMessages id="NationalID/Passport No/Driving License/ SSN No"/>}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Identity is required."
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter Identity"/>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="relation"
                                        label={<IntlMessages id="Relation"/>}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Relation is required."
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter relation"/>
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="phone"
                                        label={<IntlMessages id="Phone Number"/>}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Phone Number."
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter phone number"/>
                                    </Form.Item>
                                </div>
                            </div>
                            
                            
                            <div className="col-md-4">
                                <div className="gx-form-row0">
                                    <Form.Item
                                        name="address"
                                        label={<IntlMessages id="Address"/>}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Address is required."
                                            },
                                        ]}
                                    >
                                        <TextArea placeholder="Enter Address" rows={2}/>
                                    </Form.Item>
                                </div>
                            </div>
                
                        </div>
                
                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Save & Submit</Button>
                            </Col>
                        </Row>
                    </Form>
        
                </Col>
            </Row>
            
        </Card>
    );
}

export default BeneficiaryInfo;