import React from "react";
import {Route, Switch} from "react-router-dom";

import VendorTypeList from "../../containers/yotta/vendor_management/vendor_type/VendorTypeList";
import NewVendorType from "../../containers/yotta/vendor_management/vendor_type/NewVendorType";
import VendorInfoList from "../../containers/yotta/vendor_management/vendor_info/VendorInfoList";
import NewVendorInfo from "../../containers/yotta/vendor_management/vendor_info/NewVendorInfo";
import NewBill from "../../containers/yotta/vendor_management/bill/newbill";
import EditBill from "../../containers/yotta/vendor_management/bill/billedit";
import EditBillInfo from "../../containers/yotta/vendor_management/bill/billeditraw";
import NewBillForm from "../../containers/yotta/vendor_management/bill/newbillfromraw";
import BillDetails from "../../containers/yotta/vendor_management/bill/billdetails";
import BillHistory from "../../containers/yotta/vendor_management/bill/billhistory";
import BillPaymentHistory from "../../containers/yotta/vendor_management/bill_payment/paymenthistory";
import BillPaymentEdit from "../../containers/yotta/vendor_management/bill_payment/billpaymentedit";
import NewBillPayment from "../../containers/yotta/vendor_management/bill_payment/newpayment";
import PendingCheck from "../../containers/yotta/vendor_management/check/pendingcheck";
import CheckList from "../../containers/yotta/vendor_management/check/checklist";
import PendingBill from "../../containers/yotta/vendor_management/bill/pendingbill";
import UnpaidBill from "../../containers/yotta/vendor_management/bill/unpaidbill";
import NewVoucher from "../../containers/yotta/vendor_management/voucher/newvoucher";

const Vendor = ({match}) => (
    <Switch>
        <Route path={`${match.url}/vendortype`} component={VendorTypeList}/>
        <Route path={`${match.url}/newvendortype`} component={NewVendorType}/>
        <Route path={`${match.url}/vendortypeedit/:id`} component={NewVendorType}/>
        <Route path={`${match.url}/vendorinfo`} component={VendorInfoList}/>
        <Route path={`${match.url}/newvendorinfo`} component={NewVendorInfo}/>
        <Route path={`${match.url}/vendorinfoedit/:id`} component={NewVendorInfo}/>
        <Route path={`${match.url}/newbill`} component={NewBillForm}/>
        <Route path={`${match.url}/billhistory`} component={BillHistory}/>
        <Route path={`${match.url}/paymenthistory`} component={BillPaymentHistory}/>
        <Route path={`${match.url}/paymentedit/:id`} component={BillPaymentEdit}/>
        <Route path={`${match.url}/newpayment`} component={NewBillPayment}/>
        <Route path={`${match.url}/billedit/:id`} component={EditBill}/>
        <Route path={`${match.url}/billinfoedit/:id`} component={EditBillInfo}/>
        <Route path={`${match.url}/newbillt`} component={NewBill}/>
        <Route path={`${match.url}/billdetails/:id`} component={BillDetails}/>
        <Route path={`${match.url}/pendingcheck`} component={PendingCheck}/>
        <Route path={`${match.url}/checklist`} component={CheckList}/>
        <Route path={`${match.url}/pendingbill`} component={PendingBill}/>
        <Route path={`${match.url}/unpaidbill`} component={UnpaidBill}/>
        <Route path={`${match.url}/newvoucher`} component={NewVoucher}/>
    </Switch>
);

export default Vendor;
