import React, {useState, useEffect} from "react";
import {Form, Input, Button, Card, Select, DatePicker, notification} from 'antd';
import jsonfile from './bill.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import StaticSelectedData from "../../../../util/StaticSelectData";
import CircularProgress from "../../../../components/CircularProgress";

const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const BillInfoEdit = (props) => {

    const formRef = React.createRef();
    const [form] = Form.useForm();
    const history = useHistory();

    const [billData, setBillData] = useState([]);
    const [comp, setComp] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);
    const [companyID, setCompanyId] = useState("");

    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    let Vendors = [];
    const Companies = SelectData("company/companylists");

    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);

    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }

    const [loading, setLoading] = useState([]);
    const [polist, setPolist] = useState({
        po_number : "",
        date: moment(),
        due_date: moment(),
        reference_no: "",
        company_id: null,
        vendor_id: null,
        payable_id: null,
        fund_program_id : null,
        func_expense_id : null,
        amount : "",
        description : "",
        remark : "",
    });


    const [state, setState] = useState({
        po: {},
        items: []
    });

    const parsed = queryString.parse(props.location.search);

    const po = parsed.po;
    let PORData = [];
    let poid = 0;
    if(po > 0) {
        poid = po;
    }

    const userID = props.match.params.id;
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    let POData = {};

    const getEditData = () => {
        axios.get(jsonfile.urls.view + "/" + userID)
            .then((res) => {
                console.log("bill edit");
                console.log(res.data.bill);
                if(res.data.status === 1) {
                    setBillData(res.data.bill);
                    setCompanyId(res.data.bill.company_id)
                    // console.log("BIll : "+JSON.stringify(res.data.bill));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getEditCompanyData = (value) => {
        // const comID = CisUI().getUserInfo("com_id");
        // if(comID > 0) {
        //     document.getElementById("loader").style.display = "block";
            axios.get("billentrydata?com_id=" + value)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                    }

                    // document.getElementById("loader").style.display = "none";
                })
                .catch((error) => {
                    console.log(error);
                    // document.getElementById("loader").style.display = "none";
                });

            // formRef.current.setFieldsValue({
            //     vendor: "",
            //     accounts_payable: "",
            //     accounts_id: ""
            // });
        // }
    }

    useEffect(() => {
        getEditData();
        getEditCompanyData(companyID)

        // if(isEdit === 1) {
        //     //billData = RemoteJson(jsonfile.urls.view + "/" + userID);
        //     axios.get(jsonfile.urls.view + "/" + userID)
        //         .then((res) => {
        //             console.log("bill edit");
        //             console.log(res.data.bill);
        //             if(res.data.status === 1) {
        //                 setBillData(res.data.bill);
        //                 // console.log("BIll : "+JSON.stringify(res.data.bill));
        //             }
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
        // }



        // getPODetails();
        // const comID = CisUI().getUserInfo("com_id");
        // if(comID > 0) {
        //     document.getElementById("loader").style.display = "block";
        //     axios.get("billentrydata?com_id="+comID)
        //         .then((res) => {
        //             console.log(res.data);
        //             if(res.data.status === 1) {
        //                 setVendor(res.data.vendor);
        //                 setAccount(res.data.account);
        //                 setPayable(res.data.payable);
        //                 setFund(res.data.fund);
        //                 setExpense(res.data.expense);
        //             }
        //
        //             document.getElementById("loader").style.display = "none";
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //             document.getElementById("loader").style.display = "none";
        //         });

            // formRef.current.setFieldsValue({
            //     vendor: "",
            //     accounts_payable: "",
            //     accounts_id: ""
            // });
        // }



    }, [companyID]);

    if (isEdit === 1) {
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    const onFinish = (values) => {
        setLoading({loading: true})
        console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                console.log(errors.response.data.errors);
                setLoading({loading: false});
                document.getElementById("loader").style.display = "none";
            });
    };


    const getPODetails = () => {
        if(isEdit === 0) {
            axios.get(Config.apiserver + "purchase/podetails/" + poid)
                .then((res) => {
                    //console.log(res.data);
                    if (res.data.status === 1) {
                        const PODataD = res.data.podata;
                        setPolist({
                            po_number : PODataD.po_number,
                            date: moment(),
                            due_date: moment(),
                            reference_no: "",
                            company_id: PODataD.company_id,
                            vendor_id: PODataD.vendor_d,
                            payable_id: null,
                            fund_program_id : null,
                            func_expense_id : null,
                            amount : PODataD.grand_total,
                            description : PODataD.description,
                            remark : PODataD.remark
                        });

                        formRef.current.setFieldsValue({
                            po_number : PODataD.po_number,
                            date: moment(),
                            due_date: moment(),
                            reference_no: "",
                            company_id: PODataD.company_id,
                            vendor_id: PODataD.vendor_d,
                            payable_id: null,
                            fund_program_id : null,
                            func_expense_id : null,
                            amount : PODataD.grand_total,
                            description : PODataD.description,
                            remark : PODataD.remark
                        });

                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else {
            setPolist({
                po_number : billData.po_number,
                date: CisUI().DateFormat(billData.date),
                due_date: CisUI().DateFormat(billData.due_date),
                reference_no: billData.reference_no,
                company_id: billData.company_id,
                vendor_id: billData.vendor_d,
                payable_id: billData.payable_id,
                fund_program_id : billData.fund_program_id,
                func_expense_id : billData.func_expense_id,
                amount : billData.amount,
                description : billData.description,
                remark : billData.remark
            });
        }
    }

    const formField =
         (

            <div className="row">
                <div className="col-md-4">
                    <Form.Item
                        name="date"
                        label="Date"
                        initialValue={moment(billData.date)}
                        rules={[
                            {
                                required: true,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100" placeholder="Date"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="due_date"
                        label="Due Date"
                        initialValue={moment(billData.due_date)}
                        rules={[
                            {
                                required: true,
                                message: "Select a due date"
                            },
                        ]}
                    >
                        <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100" placeholder="Due Date"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="reference_no"
                        label="Reference#"
                        initialValue={billData.reference_no}
                        rules={[
                            {
                                required: false,
                                message: "Enter reference number"
                            },
                        ]}
                    >
                        <Input className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                    </Form.Item>
                </div>

                {
                    CisUI().getUserInfo("com_id") === 0 ?

                        <div className="col-md-4">
                            <Form.Item
                                name="company_id"
                                label="Company"
                                initialValue={billData.company_id}
                                rules={[
                                    {
                                        required: true,
                                        message: "Select a company"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a company"
                                    optionFilterProp="children"
                                    onChange={onCompanyChange}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        Companies.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        : ""
                }

                <div className="col-md-4">
                    <Form.Item
                        name="vendor_id"
                        label="Vendor"
                        initialValue={billData.vendor_id}
                        rules={[
                            {
                                required: true,
                                message: "Select a vendor"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a vendor"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                vendor.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="payable_id"
                        label="Accounts Payable"
                        initialValue={billData.payable_id}
                        rules={[
                            {
                                required: true,
                                message: "Select a accounts payable"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a payable account"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                payable.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="account_id"
                        initialValue={billData.account_id}
                        label="Account"
                        rules={[
                            {
                                required: true,
                                message: "Select an account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an account"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                accounts.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="fund_program_id"
                        label="Select a Fund Program"
                        initialValue={billData.fund_program_id}
                        rules={[
                            {
                                required: false,
                                message: "Select a fund program"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a fund prgoram"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                fund.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="func_expense_id"
                        label="Functional Expense"
                        initialValue={billData.func_expense_id}
                        rules={[
                            {
                                required: false,
                                message: "Select a functional expense"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a functional expense"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                expense.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="restriction_type"
                        label="Restriction Type"
                        initialValue={billData.restriction_type}
                        rules={[
                            {
                                required: true,
                                message: "Select a restriction type"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a restriction type"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                StaticSelectedData("restrictiontype").map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="amount"
                        label="Amount"
                        initialValue={billData.amount}
                        rules={[
                            {
                                required: true,
                                message: "Enter an amount here"
                            },
                        ]}
                    >
                        <Input placeholder="Enter an amount here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="description"
                        label="Description"
                        initialValue={billData.description}
                        rules={[
                            {
                                required: true,
                                message: "Enter description here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter description here"/>
                    </Form.Item>
                </div>

                <div className="col-md-4">
                    <Form.Item
                        name="remark"
                        label="Remark/Memo"
                        initialValue={billData.remark}
                        rules={[
                            {
                                required: false,
                                message: "Enter something here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter somthing ehre"/>
                    </Form.Item>
                </div>
            </div>
        )


    return (
        <Card title={Title} extra={
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.list)
                    : CisUI().addAction('../' + jsonfile.urls.list)
            }>
            {CisUI().showLoading}
            {
                billData == "" ? <CircularProgress/> : (
                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        ref={formRef}
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >


                        {
                            formField
                        }

                        <div className="row">
                            <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                                <Button id="submitbutton" type="primary" htmlType="submit">
                                    Save
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                            </div>
                        </div>

                    </Form>
                )
            }
        </Card>
    );
};

export default BillInfoEdit;