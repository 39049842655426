import React, {useState, useEffect} from "react";
import {Form, Row, Col, Collapse, Card} from 'antd';
import Auxiliary from "util/Auxiliary";
import {Area, AreaChart, ResponsiveContainer, Tooltip} from "recharts";
import jsonfile from "./localexpenses.json";
import {CisUI} from "../../../../util/CISUI";
import AdvancedSearch from "../../../../util/AdvancedSearch";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import Metrics from "../../../../components/Metrics";

import CanvasJSReact from './../../../canvasjs/canvasjs.react';

const Panel = Collapse.Panel;

//var CanvasJSReact = require('./canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const LocalAnalyticsDashboard = (props) => {

    const formRef = React.createRef();

    const userInfo = localStorage.getItem("userData");
    const user = JSON.parse(userInfo);

    const [form] = Form.useForm();

    const history = useHistory();

    const qst = CisUI().parseParams(props?.location?.search);
    const localID = qst["local_id"];

    const [state, setState] = useState({
        reqURL: Config.apiserver + jsonfile.urls.dashboard + "?local_id=" + localID,
        reportTitle: 'Local Dashbaord',
        incomes: [],
        expenses: [],
        districtName: "",
        totalExpense: 0,
        totalIncome: 0,
        lastmonth: {
            income: 0,
            expense: 0,
            net: 0,
            mission: 0,
            others: 0,
            total: 0
        },
        currentmonth: {
            income: 0,
            expense: 0,
            net: 0,
            mission: 0,
            others: 0,
            total: 0
        },
        memberCount: [],
        memberGrowth: 0,
        memberTotal: 0,
        titheThisYear: 0,
        titheCollection: [],
        titheGrowth: 0,
        localLastMonth: []
    });


    const IncomeExpenseOptions = {
        exportEnabled: true,
        animationEnabled: true,
        title: {
            text: "Tithe & Expenses",
            fontColor: "#27496d"
        },
        subtitles: [{
            text: "For " + state.districtName,
            fontColor: "#00a8cc",
        }],
        axisX: {
            title: "Month"
        },
        axisY: {
            title: "Tithe",
            titleFontColor: "#27496d",
            lineColor: "#27496d",
            labelFontColor: "#27496d",
            tickColor: "#27496d",
            includeZero: true
        },
        axisY2: {
            title: "Expenses",
            titleFontColor: "#00a8cc",
            lineColor: "#00a8cc",
            labelFontColor: "#00a8cc",
            tickColor: "#00a8cc",
            includeZero: true
        },
        toolTip: {
            shared: true
        },
        data: [{
            type: "column",
            name: "Tithe",
            color: "#27496d",
            showInLegend: true,
            yValueFormatString: "$ #,##0.#",
            dataPoints: state.incomes
        },
            {
                type: "column",
                name: "Expense",
                color: "#00a8cc",
                showInLegend: true,
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.expenses
            }]
    }

    const colorset =
        [
            "#0f3057",
            "#00587a",
            "#008891",
            "#51adcf",
            "#0cd0e9"
        ];

    const LastMonthDataLocal = {
        exportEnabled: true,
        animationEnabled: true,
        colorSet : colorset,
        title: {
            text: "LAST MONTH NET TITHE FROM LOCAL",
            fontColor: "#27496d"
        },
        legend: {
            cursor: "pointer"
        },
        data: [{
            type: "pie",
            showInLegend: true,
            colorSet : colorset,
            indexLabel: "{name} - {y}",
            yValueFormatString: "$ #,##0.#",
            dataPoints: state.localLastMonth
        }]
    }

    useEffect(() => {
        if(CisUI().getUserInfo('com_id') > 0) {
            getData(state.reqURL);
        }
    }, []);

    const getData = (newURL) => {
        document.getElementById("loader").style.display = "block";
        axios.get(newURL)
            .then((res) => {
                // console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if (res.data.status === 1) {
                    //console.log("Response : "+ res.data);
                    const data = res.data;
                   // console.log(data.incomeexpense);
                    setState({
                        incomes: data.income,
                        expenses: data.expesne,
                        districtName: data.district,
                        totalIncome: data.totalIncome,
                        totalExpense: data.totalExpense,
                        lastmonth: data.lastmonth,
                        currentmonth: data.currentmonth,
                        memberTotal: data.memberTotal,
                        memberCount: data.memberCount,
                        memberGrowth: data.memberGrowth,
                        titheThisYear: data.titheThisYear,
                        titheCollection: data.titheCollection,
                        titheGrowth: data.titheGrowth,
                        localLastMonth: data.localLastMonth,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    const onFinish = values => {
        console.log('Received values of form: ', values);
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.dashboard + "?" + qsup;
        //console.log("NEURL :" + newURL);
        //console.log("Month : " + wkd);
        const WKR = CisUI().ParseDateFormat(wk);
        const reportTitle = "Between " + CisUI().DateFormat(WKR[0]) + " and " + CisUI().DateFormat(WKR[1]);

        const url = Config.apiserver + jsonfile.urls.dashboard;
        //const data = SelectData(url);

        getData(newURL);

    };

    let searchView = "";
    if (jsonfile.has_search) {
        searchView = <Card className="advance-search-card no-print">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']}>
                <Panel header="Advance Search" key="1">
                    <Form
                        key={5}
                        form={form}
                        ref={formRef}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1)}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    return (
        <Auxiliary>
            {searchView}
            <div>
                {CisUI().showLoading}
                <Row>
                    <Col span={24}>
                        <div className="gx-card">
                            <div className="gx-card-body">
                                <Row>
                                    <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-audi-col">

                                        <div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
                                            <h3 className="gx-text-uppercase gx-mb-2 gx-mb-sm-4">Summary For Current
                                                Year</h3>
                                            <table className="table table-bordered">
                                                <tr>
                                                    <th style={{textAlign: 'center'}}>Gross Tithe</th>
                                                    <th style={{textAlign: 'center'}}>Total Expenses</th>
                                                </tr>
                                                <tr>
                                                    <th className="amountSummary"
                                                        style={{color: '#27496d'}}>{CisUI().getCurrencyFormated1(state.totalIncome)}</th>
                                                    <th className="amountSummary"
                                                        style={{color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.totalExpense)}</th>
                                                </tr>
                                            </table>
                                            <div>
                                                <h4 style={{color: '#27496d'}}>Previous Month</h4>
                                                <table className="table table-bordered">
                                                    <tr>
                                                        <th>Title</th>
                                                        <th style={{textAlign: 'right'}}>Amount</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Net Tithe and Offering</td>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.lastmonth.net)}</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Add: Missions Offering</td>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.lastmonth.mission)}</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Add: Other Contributions</td>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.lastmonth.others)}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Total</th>
                                                        <th style={{
                                                            textAlign: 'right',
                                                            color: '#27496d'
                                                        }}>{CisUI().getCurrencyFormated1(state.lastmonth.total)}</th>
                                                    </tr>
                                                </table>
                                            </div>

                                            <div>
                                                <h4 style={{color: '#00a8cc'}}>Current Month</h4>
                                                <table className="table table-bordered">
                                                    <tr>
                                                        <th>Title</th>
                                                        <th style={{textAlign: 'right'}}>Amount</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Net Tithe and Offering</td>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.currentmonth.net)}</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Add: Missions Offering</td>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.currentmonth.mission)}</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Add: Other Contributions</td>
                                                        <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.currentmonth.others)}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Total</th>
                                                        <th style={{
                                                            textAlign: 'right',
                                                            color: '#00a8cc'
                                                        }}>{CisUI().getCurrencyFormated1(state.currentmonth.total)}</th>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                    </Col>

                                    <Col xl={18} lg={24} md={24} sm={24} xs={24} className="gx-visit-col">
                                        <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
                                            <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">Income &
                                                Expenditure</h6>
                                            <CanvasJSChart options={IncomeExpenseOptions}
                                                /* onRef = {ref => this.chart = ref} */
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-visit-col">
                        <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
                            <div className="gx-card">
                                <div className="gx-card-body">
                                    <CanvasJSChart options={LastMonthDataLocal}/>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{display: 'none'}}>
                    <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                        <Metrics styleName={`gx-card-full`} title={"Total Member : " + state.memberTotal}>
                            <Row>
                                <Col lg={9} md={24} sm={9} xs={9}>
                                    <div className="gx-pb-4 gx-pl-4 gx-pt-4">
                                        <h2 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-chart-up">{state.memberGrowth}%
                                            <i className="icon icon-menu-up gx-fs-xxl"/>
                                        </h2>
                                        <p className="gx-mb-0 gx-text-grey">This Month</p>
                                    </div>
                                </Col>
                                <Col lg={15} md={24} sm={15} xs={15}>
                                    <ResponsiveContainer width="100%" height={103}>
                                        <AreaChart data={state.memberCount}
                                                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                            <Tooltip/>
                                            <defs>
                                                <linearGradient id="color1" x1="0" y1="0" x2="1" y2="0">
                                                    <stop offset="5%" stopColor="#27496d" stopOpacity={0.9}/>
                                                    <stop offset="95%" stopColor="#00a8cc" stopOpacity={0.9}/>
                                                </linearGradient>
                                            </defs>
                                            <Area dataKey='value' strokeWidth={0} stackId="2" stroke='#867AE5'
                                                  fill="url(#color1)" fillOpacity={1}/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </Col>
                            </Row>
                        </Metrics>
                    </Col>
                    <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                        <Metrics styleName={`gx-card-full`}
                                 title={"Tithe Collection : " + CisUI().getCurrencyFormated1(state.titheThisYear)}>
                            <Row>
                                <Col lg={9} md={24} sm={9} xs={9}>
                                    <div className="gx-pb-4 gx-pl-4 gx-pt-4">
                                        <h2 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-chart-up">{state.titheGrowth}%
                                            <i className="icon icon-menu-up gx-fs-xxl"/>
                                        </h2>
                                        <p className="gx-mb-0 gx-text-grey">This Year</p>
                                    </div>
                                </Col>
                                <Col lg={15} md={24} sm={15} xs={15}>
                                    <ResponsiveContainer width="100%" height={103}>
                                        <AreaChart data={state.titheCollection}
                                                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                            <Tooltip/>
                                            <defs>
                                                <linearGradient id="color1" x1="0" y1="0" x2="1" y2="0">
                                                    <stop offset="5%" stopColor="#27496d" stopOpacity={0.9}/>
                                                    <stop offset="95%" stopColor="#00a8cc" stopOpacity={0.9}/>
                                                </linearGradient>
                                            </defs>
                                            <Area dataKey='value' strokeWidth={0} stackId="2" stroke='#867AE5'
                                                  fill="url(#color1)" fillOpacity={1}/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </Col>
                            </Row>
                        </Metrics>
                    </Col>
                    <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                        <Metrics styleName={`gx-card-full`} title={"Total Income : " + state.memberTotal}>
                            <Row>
                                <Col lg={9} md={24} sm={9} xs={9}>
                                    <div className="gx-pb-4 gx-pl-4 gx-pt-4">
                                        <h2 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 gx-chart-up">{state.memberGrowth}%
                                            <i className="icon icon-menu-up gx-fs-xxl"/>
                                        </h2>
                                        <p className="gx-mb-0 gx-text-grey">This Month</p>
                                    </div>
                                </Col>
                                <Col lg={15} md={24} sm={15} xs={15}>
                                    <ResponsiveContainer width="100%" height={103}>
                                        <AreaChart data={state.memberCount}
                                                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                            <Tooltip/>
                                            <defs>
                                                <linearGradient id="color1" x1="0" y1="0" x2="1" y2="0">
                                                    <stop offset="5%" stopColor="#27496d" stopOpacity={0.9}/>
                                                    <stop offset="95%" stopColor="#00a8cc" stopOpacity={0.9}/>
                                                </linearGradient>
                                            </defs>
                                            <Area dataKey='value' strokeWidth={0} stackId="2" stroke='#867AE5'
                                                  fill="url(#color1)" fillOpacity={1}/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </Col>
                            </Row>
                        </Metrics>
                    </Col>
                </Row>

            </div>

        </Auxiliary>
    );
};

export default LocalAnalyticsDashboard;
