import React, {useEffect, useState} from "react";
import {Row, Col, Form, Card, Input, Select, notification, Button} from 'antd';
import jsonfile from './skillinfo.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import StaticSelectData from "../../../../../util/StaticSelectData";
import {CisUI} from "../../../../../util/CISUI";
import Config from "../../../../../util/config";
import axios from "axios";

const {Option} = Select;
const {TextArea} = Input
const SkillInfo = (props) => {

    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState([]);

    const userID = props.empid;
    const editID = props.editEmpId;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(editID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add+"/"+userID;
    let redirectto = "../"+jsonfile.urls.list;

    let Levels = StaticSelectData('skill_level')

    const [state, setState] = useState({
        rows: [
            {
                id:"",
                skill_name: "",
                details: "",
                level : ""
            }
        ]
    });

    let edit_data = [];

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+editID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+editID;
        redirectto = "../../"+jsonfile.urls.list;

        userData.forEach((item, index) =>
            edit_data.push({
                id: item.id,
                skill_name: item.skill_name,
                details: item.details,
                level : item.level
            })
        )
    }

    useEffect(() => {
        if(isEdit === 1) {
            setState({
                rows: edit_data
            })
        }
    }, [userData]);


    const handleAdd = () => {
        // console.log("hi");
        const item = {
            id: 0,
            skill_name: "",
            details: "",
            level : ""
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    const handleRemoveRow = (idx) => e => {
        // console.log(idx);

        const filteredArray = [...state.rows];
        
        if(filteredArray.length == 1) {
            notification.warning({
                message: 'Alert',
                type : "warning",
                description: "You can not delete this item."
            });
        } else {
            setState({
                rows: filteredArray.filter((item, index) => item.id !== idx)
            });
    
            axios.delete(Config.apiserver + 'hrm/singleskillinfodelete/' + idx, CisUI().HeaderRequest)
                .then(res => {
                    // console.log(res.data)
                    if (res.data.status === 1) {
                        CisUI().Notification('success', res.data.msg)
                    } else {
                        CisUI().Notification('error', res.data.msg)
                    }
                })
                .catch(error => console.log(error))
        }

    };

    const handleAddChange = (id) => e => {

        const { name, value } = e.target;
        const rows = [...state.rows];
        // console.log("selectedITems:"+ JSON.stringify(rows[id]));

        if(name === "id") {
            rows[id] = {
                id: value,
                skill_name: rows[id].skill_name,
                details: rows[id].details,
                level: rows[id].level
            };
        }
        else if(name === "skill_name") {
            rows[id] = {
                id: rows[id].id,
                skill_name: value,
                details: rows[id].details,
                level: rows[id].level
            };
        }
        else if(name === "details") {
            rows[id] = {
	            id: rows[id].id,
                skill_name: rows[id].skill_name,
                details: value,
                level: rows[id].level
            };
        }
        else if(name === "level") {
            rows[id] = {
                id: rows[id].id,
                skill_name: rows[id].skill_name,
                details: rows[id].details,
                level: value
            };
        }

        setState({
            rows
        });

    }

    const onFinish = (values) => {
        values["skill_info"] = state.rows;
        document.getElementById("loader").style.display = "block";

        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                console.log(res.data)
                if(res.data.status === 1) {
                   CisUI().Notification('success', res.data.msg)
                }
                else {
                    CisUI().Notification('error', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    }


    return (
        <Card title={Title}>
            {CisUI().showLoading}
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            {/*<h2>Purchase Items</h2>*/}
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAdd}>Add More</a>
                        </div>
                    </div>

                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                    >
                        <table id="educationinfo" className="ptable table table-bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Skill Name</th>
                                <th>Details</th>
                                <th>Level</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.rows.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                    <td style={{width : '30px'}}>
                                        <a onClick={handleRemoveRow(state.rows[idx].id)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
                                        {/*<Popconfirm title="Sure to delete?" onConfirm={handleRemoveRow(idx)}>*/}
                                        {/*    <a className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>*/}
                                        {/*</Popconfirm>*/}
                                    </td>
                                    <td style={{width : '150px'}}>
                                        <input type="hidden" className="form-control" name="number" value={state.rows[idx].id} onChange={handleAddChange(idx)} />
                                        <input type="text" className="form-control" placeholder="Skill Name" name="skill_name" value={state.rows[idx].skill_name} onChange={handleAddChange(idx)} />
                                    </td>
                                    <td style={{width : '100px'}}><textarea className="form-control" name="details" value={state.rows[idx].details} onChange={handleAddChange(idx)} /></td>
                                    <td style={{width : '100px'}}>
                                        <select name="level" id="level" className="form-control custom-select" onChange={handleAddChange(idx)} value={state.rows[idx].level}>
                                            <option value="">Select An Option</option>
                                            {
                                                Levels.map((items, index) =>
                                                    <option key={++index} value={items.id}>{items.name}</option>
                                                )
                                            }

                                        </select>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Save & Submit</Button>
                            </Col>
                        </Row>

                    </Form>

                </div>
            </div>
        </Card>
    );
}

export default SkillInfo;