import React from "react";
import {Form, Card, notification} from 'antd';
import jsonfile from './task.json'
import {CisUI} from '../../../../../util/CISUI'
import InputFields from '../../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import IntlMessages from "../../../../../util/IntlMessages";

const NewTask = (props) => {

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        // setLoading({loading:true})
        // console.log("Input Data : "+values);
        // console.log(values);
        const data = {
            ...values,
            'task_date' : values['task_date'].format("YYYY-MM-DD"),
        };
        // let msg = "Sorry! request not processed, please try again";
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <Card title={<IntlMessages id={Title}/>} extra={
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }>
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit,props)}*/}
                {InputFields(jsonfile.input,userData,isEdit,props)}
            </Form>
        </Card>
    );
};

export default NewTask;