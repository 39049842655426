import {Form, Row, Col, Input, Button, Card, Collapse, Modal, Select, DatePicker, Checkbox, notification, Spin} from 'antd';
import React, {useState, useEffect} from "react";
import jsonfile from './banktransaction.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import moment from "moment";
import 'moment/locale/es-us';

const {Option} = Select;
const {TextArea} = Input;
const queryString = require('query-string');


const BankReconcilation = (props) => {

    const formRef = React.createRef();
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const history = useHistory();

    const [isAllow, setAllow] = useState(true);
    const [title, setTitle] = useState(jsonfile.new_deposit);
    const [hasPayment, setHasPayment] = useState(false);
    const [uinputs, setInputs] = useState(
        {
            service_date: null,
            service_charge:0,
            expense_account_id:null,
            func_expenses:null,
            interest_date : null,
            interest_charge:0,
            income_account_id:null,
            fund_program:null,
            balance:0,
            ending_balance:0,
        }
    );
    const [payments, setPayment] = useState([]);
    const [isApprover, setApprover] = useState(false);
    const [hasBill, setHasbill] = useState(false);
    const [bill, setBill] = useState({});
    const [comp, setComp] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);
    const [fexpense, setfExpense] = useState([]);
    const [incomes, setIncomes] = useState([]);
    const [bankaccount, setBankAccount] = useState([]);
    const [cashaccount, setCashAccount] = useState([]);


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

    const [paymentmethod, setPaymentmethod] = useState([]);


    let Vendors = [];
    const Companies = SelectData("company/companylists");

    const [comapny, setCompany] = useState([]);
    const [local, setLocal] = useState([]);

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        setTitle(jsonfile.edittitle);
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }



    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setfExpense(res.data.expense);
                        setExpense(res.data.expense_account);
                        setIncomes(res.data.income_account);
                        setBankAccount(res.data.bank);
                        setCashAccount(res.data.cashaccount);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            // formRef.current.setFieldsValue({
            //     vendor: "",
            //     accounts_payable: "",
            //     accounts_id: ""
            // });
        }
    }

    useEffect(() => {
        const comID = CisUI().getUserInfo('com_id');
        if(comID > 0) {
            axios.get("billentrydata?com_id="+comID)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setfExpense(res.data.expense);
                        setExpense(res.data.expense_account);
                        setIncomes(res.data.income_account);
                        setBankAccount(res.data.bank);
                        setCashAccount(res.data.cashaccount);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    const updateCompanyList = () => {
        axios.get("billentrydata?com_id="+userData.company_id)
            .then((res) => {
                console.log(res.data);
                if(res.data.status === 1) {
                    setVendor(res.data.vendor);
                    setAccount(res.data.account);
                    setPayable(res.data.payable);
                    setFund(res.data.fund);
                    setfExpense(res.data.expense);
                    setExpense(res.data.expense);
                    setCashAccount(res.data.cashaccount);
                    setExpense(res.data.expense_account);
                    setIncomes(res.data.income_account);
                    setBankAccount(res.data.bank);

                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [loading, setLoading] = useState([]);

    const [state, setState] = useState({
        balance : 0,
        ending_balance : 0,
        service_date: null,
        service_charge:0,
        expense_account_id:null,
        func_expenses:null,
        interest_date : null,
        interest_charge:0,
        income_account_id:null,
        fund_program:null,
    });

    const parsed = queryString.parse(props.location.search);

    const po = parsed.po;
    let PORData = [];
    let poid = 0;
    if(po > 0) {
        poid = po;
    }



    const onFinish = (values) => {
        setLoading({loading: true})
        values["balance"] = state.balance;
        values["ending_balance"] = state.ending_balance;

        let com_id = CisUI().getUserInfo('com_id');
        if(com_id === 0) {
            com_id = values['com_id'];
        }
        else {
            values['com_id'] = com_id;
        }
        const date1 = values['month'];
        const date = CisUI().ParseDateFormatFromMonth(date1);
        //console.log(date);
        const account_id = values['account_id'];
        let incomeAct = "";
        let expenseAct = "";
        if(values['income_account_id'] > 0) {
             const inc = incomes.filter(items => items.id === values['income_account_id']);
             if(inc.length > 0) {
                 values['incomeAct'] = inc[0].name;
             }
        }

        if(values['expense_account_id'] > 0) {
            const exp = expense.filter(items => items.id === values['expense_account_id']);
            if(exp.length > 0) {
                values['expenseAct'] = exp[0].name;
            }
        }

        if(values['func_expenses'] > 0) {
            const exp = fexpense.filter(items => items.id === values['func_expenses']);
            if(exp.length > 0) {
                values['fexpenseAct'] = exp[0].name;
            }
        }

        if(values['fund_program'] > 0) {
            const exp = fund.filter(items => items.id === values['fund_program']);
            if(exp.length > 0) {
                values['fundAct'] = exp[0].name;
            }
        }



        console.log(JSON.stringify(values));

        if(com_id > 0 && date !=='' && account_id > 0 && (state.ending_balance > 0 || state.ending_balance < 0)) {
            localStorage.setItem("reconcile", JSON.stringify(values));
            history.push("reconcilestart?account_id=" + account_id);
        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Please select a company, date, and enter ending balance"
            });
        }

    };

    let POData = {};

    const onAccountChange = (e) => {
        let com_id = CisUI().getUserInfo('com_id');
        if(com_id === 0) {
            com_id = formRef.current.getFieldValue('com_id');
        }
        const date1 = formRef.current.getFieldValue('month');
        const date = CisUI().ParseDateFormatFromMonth(date1);
        console.log(date);
        const account_id = formRef.current.getFieldValue('account_id');

        if(com_id > 0 && date !=='' && account_id > 0) {
            setState({
                balance : 0
            });
            document.getElementById("loader").style.display = "block";
            axios.get(Config.apiserver+"getbegainingbalance?com_id="+com_id+"&account_id="+account_id+"&date="+date)
                .then(res => {
                    //console.log(res)
                    document.getElementById("loader").style.display = "none";
                    if (res.data.status === 1) {
                        const balance = res.data.balance;
                        const inputsD = res.data.inputs;

                        var sdate = new Date(inputsD.service_date);
                        var idate = new Date(inputsD.interest_date);

                        let svdate = null;
                        let ivdate = null;
                        if(state.service_date !== "") {
                            svdate = moment(sdate,"YYYY-MM-DD");
                        }
                        if(state.service_date !== "") {
                            ivdate = moment(idate,"YYYY-MM-DD");
                        }

                        setState({
                            balance : balance,
                            ending_balance : res.data.inputs.ending_balance || 0,
                            service_date: svdate,
                            service_charge: inputsD.service_charge || 0,
                            expense_account_id: inputsD.expense_account_id || null,
                            func_expenses: inputsD.func_expenses || null,
                            interest_date : ivdate,
                            interest_charge: inputsD.interest_charge || 0,
                            income_account_id: inputsD.income_account_id || null,
                            fund_program: inputsD.fund_program || null,
                        });

                        // props.form.setFieldsValue({
                        //     service_charge : 200
                        // });
                        // form.interest_charge.setVal(22);

                        form.setFieldsValue({
                            service_date: svdate,
                            interest_date: ivdate,
                            service_charge: inputsD.service_charge || 0,
                            expense_account_id: inputsD.expense_account_id || null,
                            func_expenses: inputsD.func_expenses || null,
                            interest_charge: inputsD.interest_charge || 0,
                            income_account_id: inputsD.income_account_id || null,
                            fund_program: inputsD.fund_program || null,
                        });


                    }
                    else if (res.data.status === 3) {
                        setAllow(false);
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });

                        setState({
                            balance : 0,
                            ending_balance : 0,
                            service_date: null,
                            service_charge: state.service_charge || 0,
                            expense_account_id: state.expense_account_id || null,
                            func_expenses: state.func_expenses || null,
                            interest_date : null,
                            interest_charge: state.interest_charge || 0,
                            income_account_id: state.income_account_id || null,
                            fund_program: state.fund_program || null,
                        });

                        form.setFieldsValue({
                            service_date: null,
                            interest_date: null,
                            service_charge: 0,
                            expense_account_id: null,
                            func_expenses: null,
                            interest_charge: 0,
                            income_account_id: null,
                            fund_program: null,
                        });
                    }
                    else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                        setState({
                            balance : 0,
                            ending_balance : 0
                        });

                        form.setFieldsValue({
                            service_date: null,
                            interest_date: null,
                            service_charge: 0,
                            expense_account_id: null,
                            func_expenses: null,
                            interest_charge: 0,
                            income_account_id: null,
                            fund_program: null,
                        });

                    }
                    setLoading({loading: false});
                })
                .catch(errors => {
                    document.getElementById("loader").style.display = "none";
                   // console.log(errors.response.data.errors);
                    setLoading({loading: false});
                    setState({
                        balance : 0,
                        ending_balance : 0,
                        service_date: null,
                        service_charge: state.service_charge || 0,
                        expense_account_id: state.expense_account_id || null,
                        func_expenses: state.func_expenses || null,
                        interest_date : null,
                        interest_charge: state.interest_charge || 0,
                        income_account_id: state.income_account_id || null,
                        fund_program: state.fund_program || null,
                    });

                    form.setFieldsValue({
                        service_date: null,
                        interest_date: null,
                        service_charge: 0,
                        expense_account_id: null,
                        func_expenses: null,
                        interest_charge: 0,
                        income_account_id: null,
                        fund_program: null,
                    });

                });
        }
        else {
            if(com_id > 0) {
                notification.warning({
                    message: 'Alert',
                    type: "warning",
                    description: "Please select a month"
                });
            }
            else {
                notification.warning({
                    message: 'Alert',
                    type: "warning",
                    description: "Please select a company and date"
                });
            }
        }
    }

    const onEndingBalanceChange = (e) => {

        var sdate = new Date(state.service_date);
        var idate = new Date(state.interest_date);
        let svdate = null;
        let ivdate = null;
        if(state.service_date !== "" || this.state.service_date !== null) {
            svdate = moment(sdate,"YYYY-MM-DD");
        }
        if(state.service_date !== "" || this.state.service_date !== null) {
            ivdate = moment(idate,"YYYY-MM-DD");
        }

        setState({
            balance : state.balance,
            ending_balance : e.target.value,
            service_date: svdate,
            service_charge: state.service_charge || 0,
            expense_account_id: state.expense_account_id || null,
            func_expenses: state.func_expenses || null,
            interest_date : ivdate,
            interest_charge: state.interest_charge || 0,
            income_account_id: state.income_account_id || null,
            fund_program: state.fund_program || null,
        });

        form.setFieldsValue({
            service_date: svdate,
            interest_date: ivdate,
            service_charge: state.service_charge || 0,
            expense_account_id: state.expense_account_id || null,
            func_expenses: state.func_expenses || null,
            interest_charge: state.interest_charge || 0,
            income_account_id: state.income_account_id || null,
            fund_program: state.fund_program || null,
        });
    }

    const noBillField = (stateData) => {
        // console.log(stateData);
        return <>
            <div className="row">
                {/*{console.log(uinputs)}*/}
                {CisUI().getUserInfo('com_id') === 0 ?
                    <div className="col-md-3">
                        <Form.Item
                            name="com_id"
                            label="Company"
                            rules={[
                                {
                                    required: true,
                                    message: "Select an company"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select an company"
                                optionFilterProp="children"
                                onChange={onCompanyChange}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {
                                    Companies.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    : ""
                }
                <div className="col-md-3">
                    <Form.Item
                        name="month"
                        label="Month"
                        onChange={onAccountChange}
                        rules={[
                            {
                                required: true,
                                message: "Select a month"
                            },
                        ]}
                    >
                        <DatePicker picker="month" className="gx-mb-3 gx-w-100"
                                    placeholder="Month"/>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="account_id"
                        label="Account"
                        rules={[
                            {
                                required: true,
                                message: "Select an account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an account"
                            optionFilterProp="children"
                            onChange={onAccountChange}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                accounts.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <label>Beginning Balance</label>
                    <input className="form-control" readOnly={true} name="beg_balance" value={stateData.balance}/>
                </div>
                <div className="col-md-3">
                    <label>Ending Balance</label>
                    <input className="form-control" name="ending_balance" onChange={onEndingBalanceChange}
                           id="ending_balance" value={stateData.ending_balance}/>
                </div>
            </div>


            <div className="row" style={{padding: '10px'}}>
                <h3>Enter the service charge if necessary</h3>
                <div className="col-md-3">
                    <Form.Item
                        name="service_date"
                        label="Date"
                        initialValue={stateData.service_date}
                        rules={[
                            {
                                required: false,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker picker="date" defaultValue={moment()} className="gx-mb-3 gx-w-100"
                                    placeholder="date"/>
                    </Form.Item>
                </div>
                <div className="col-md-3">
                    <Form.Item
                        name="service_charge"
                        label="Service Charge"
                        initialValue={stateData.service_charge}
                        rules={[
                            {
                                required: false,
                                message: "Input an amount"
                            },
                        ]}
                    >
                        <Input className="gx-mb-3 gx-w-100" placeholder="Enter an amount"/>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="expense_account_id"
                        label="Expense Account"
                        initialValue={stateData.expense_account_id}
                        rules={[
                            {
                                required: false,
                                message: "Select an expense account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an expense account"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                expense.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-md-3">
                    <Form.Item
                        name="func_expenses"
                        label="Functional Expenses"
                        initialValue={stateData.func_expenses}
                        rules={[
                            {
                                required: false,
                                message: "Select a functional expenses"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a functional expenses"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                fexpense.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

            </div>

            <div className="row" style={{padding: '10px'}}>
                <h3>Enter the interest earned, if necessary</h3>
                <div className="col-md-3">
                    <Form.Item
                        name="interest_date"
                        label="Interest Date"
                        initialValue={stateData.interest_date}
                        rules={[
                            {
                                required: false,
                                message: "Select a interest date"
                            },
                        ]}
                    >
                        <DatePicker picker="date" defaultValue={moment()} className="gx-mb-3 gx-w-100"
                                    placeholder="date"/>
                    </Form.Item>
                </div>
                <div className="col-md-3">
                    <Form.Item
                        name="interest_charge"
                        label="Interest Amount"
                        initialValue={stateData.interest_charge}
                        rules={[
                            {
                                required: false,
                                message: "Input an interest amount"
                            },
                        ]}
                    >
                        <Input className="gx-mb-3 gx-w-100"
                               placeholder="Enter an amount"/>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="income_account_id"
                        label="Income Account"
                        initialValue={stateData.income_account_id}
                        rules={[
                            {
                                required: false,
                                message: "Select an income account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select an income account"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                incomes.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="fund_program"
                        label="Fund Program"
                        initialValue={stateData.fund_program}
                        rules={[
                            {
                                required: false,
                                message: "Select a Fund program"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a fund program"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                fund.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                    {
                        isAllow ? <>
                            <Button id="submitbutton" type="primary" htmlType="submit">
                                Start Reconciling
                            </Button>
                            <a class="ant-btn ant-primary" onClick={() => history.goBack()}>Cancel</a>
                        </> : <>
                            <h3 style={{color: 'red'}}>Reconciliation already done for the selected month and account,
                                so you can not create it again</h3>
                        </>}

                </div>
            </div>


        </>
    }


    return (
        <Card title={"New Bank Reconciliation"}>
            {
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.list)
                    : CisUI().addAction('../' + jsonfile.urls.list)
            }
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={state}
            >
                {CisUI().showLoading}
                {
                    noBillField(state)
                }
            </Form>
        </Card>
    );
};

export default BankReconcilation;