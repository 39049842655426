import {Form, Row, Col, Button, Card, notification} from 'antd';
import React, {useState} from "react";
import jsonfile from './exepense.json';
import jsonMemberfile from './../member/member.json';
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import $ from "jquery";
import InputOnly from "../../../../util/InputOnly";

const ExpenseEdit = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);

    const [form] = Form.useForm();

    let endpoint = jsonfile.urls.expenseedit;
    const redirectto = "../../../"+jsonfile.urls.list;
    const history = useHistory();

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = "Expense Details";
    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.expenseview+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.expenseedit+"/"+userID;
    }


    const onFinish = (values) => {
        setLoading({loading:true})
        console.log("Input Data : "+values);
        let msg = "Sorry! request not processed, please try again";
        values["is_ministry"] = 0;
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
                //document.getElementById("loader").style.display = "none";
            });
    };


    return (
        <Card title={"Expense Details"} extra={
            isEdit === 1
                ? CisUI().addAction("../../"+jsonfile.urls.list)
                : CisUI().addAction("../"+jsonfile.urls.list)
        }>
            {CisUI().showLoading}
            <Form
                form={form}
                ref={formRef}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                <Row gutter={24} style={{paddingLeft: '30px'}}>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Date </label>
                        <div>{CisUI().DateFormat(userData.date)}</div>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Month </label>
                        <div>{CisUI().getMonthTitle(userData.month)}</div>
                    </div>
                </Row>

                <Row gutter={24} style={{marginTop: '30px'}}>
                    {InputOnly(jsonfile.ExpenseEdit,userData,isEdit)}
                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </Col>
                </Row>

            </Form>


        </Card>
    );
};

export default ExpenseEdit;