import React, {useEffect, useState} from "react";
import {Row, Col, Form, Card, Input, Select, Button, notification} from 'antd';
import jsonfile from './languageinfo.json';
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../../util/gerremotedata";
import axios from "axios";
import StaticSelectData from "../../../../../util/StaticSelectData";
import {CisUI} from "../../../../../util/CISUI";
import Config from "../../../../../util/config";

const {Option} = Select;
const {TextArea} = Input
const LanguageInfo = (props) => {

    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState([]);
    const userID = props.empid;
    const editID = props.editEmpId;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(editID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add+"/"+userID;
    let redirectto = "../"+jsonfile.urls.list;

    let Language = StaticSelectData('language')

    const [state, setState] = useState({
        rows: [
            {
                id: "",
                language_name: "",
                reading: "",
                writing : "",
                speaking : "",
                listening : ""
            }
        ]
    });

    let edit_data = [];

    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+editID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+editID;
        redirectto = "../../"+jsonfile.urls.list;

        userData.forEach((item, index) =>
            edit_data.push({
                id: item.id,
                language_name: item.language_name,
                reading: item.reading,
                writing : item.writing,
                speaking : item.speaking,
                listening : item.listening
            })
        )
    }

    useEffect(() => {
        if(isEdit === 1){
            setState({
                rows: edit_data
            })
        }
    }, [userData]);


    const handleAdd = () => {
        // console.log("hi");
        const item = {
            id: 0,
            language_name: "",
            reading: "",
            writing : "",
            speaking : "",
            listening : ""
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    const handleRemoveRow = (idx) => e => {
        // console.log(idx);
        const filteredArray = [...state.rows];
        if(filteredArray.length == 1) {
            CisUI().Notification('error', 'You can not delete this item.')
        } else {
            setState({
                rows: filteredArray.filter((item, index) => item.id !== idx)
            });
    
            axios.delete(Config.apiserver + 'hrm/singlelanguageinfodelete/' + idx, CisUI().HeaderRequest)
                .then(res => {
                    // console.log(res.data)
                    if (res.data.status === 1) {
                        CisUI().Notification('success', res.data.msg)
                    } else {
                        CisUI().Notification('error', res.data.msg)
                    }
                })
                .catch(error => console.log(error))
        }

    };

    const handleAddChange = (id) => e => {

        const { name, value } = e.target;
        const rows = [...state.rows];
        // console.log("selectedITems:"+ JSON.stringify(rows[id]));
    
        if(name === "id") {
            rows[id] = {
                id: value,
                language_name: rows[id].language_name,
                reading: rows[id].reading,
                writing: rows[id].writing,
                speaking: rows[id].speaking,
                listening: rows[id].listening
            };
        }
        else if(name === "language_name") {
            rows[id] = {
                id: rows[id].id,
                language_name: value,
                reading: rows[id].reading,
                writing: rows[id].writing,
                speaking: rows[id].speaking,
                listening: rows[id].listening
            };
        }
        else if(name === "reading") {
            rows[id] = {
                id: rows[id].id,
                language_name: rows[id].language_name,
                reading: value,
                writing: rows[id].writing,
                speaking: rows[id].speaking,
                listening: rows[id].listening
            };
        }
        else if(name === "writing") {
            rows[id] = {
                id: rows[id].id,
                language_name: rows[id].language_name,
                reading: rows[id].reading,
                writing: value,
                speaking: rows[id].speaking,
                listening: rows[id].listening
            };
        }
        else if(name === "speaking") {
            rows[id] = {
                id: rows[id].id,
                language_name: rows[id].language_name,
                reading: rows[id].reading,
                writing: rows[id].writing,
                speaking: value,
                listening: rows[id].listening
            };
        }
        else if(name === "listening") {
            rows[id] = {
                id: rows[id].id,
                language_name: rows[id].language_name,
                reading: rows[id].reading,
                writing: rows[id].writing,
                speaking: rows[id].speaking,
                listening: value
            };
        }

        setState({
            rows
        });

    }

    const onFinish = (values) => {
        values["language_info"] = state.rows;
        document.getElementById("loader").style.display = "block";

        axios.post(Config.apiserver + endpoint, values, CisUI().HeaderRequest)
            .then(res => {
                // console.log(res.data)
                if(res.data.status === 1) {
                    CisUI().Notification('success', res.data.msg)
                }
                else {
                    CisUI().Notification('success', res.data.msg)
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    }

    return (
        <Card title={Title}>
            {CisUI().showLoading}
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            {/*<h2>Purchase Items</h2>*/}
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-primary" onClick={handleAdd}>Add More</a>
                        </div>
                    </div>

                    <Form
                        form={form}
                        {...CisUI().formItemLayout}
                        name="input"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                    >
                        <table id="educationinfo" className="ptable table table-bordered">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Language Name</th>
                                <th>Reading</th>
                                <th>Writing</th>
                                <th>Speaking</th>
                                <th>Listening</th>
                            </tr>
                            </thead>
                            <tbody>
                            {state.rows.map((item, idx) => (
                                <tr id="addr0" key={idx}>
                                    <td style={{width : '30px'}}>
                                        <a onClick={handleRemoveRow(state.rows[idx].id)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>
                                        {/*<Popconfirm title="Sure to delete?" onConfirm={handleRemoveRow(idx)}>*/}
                                        {/*    <a className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a>*/}
                                        {/*</Popconfirm>*/}
                                    </td>
                                    <td style={{width : '150px'}}>
                                        <input type="hidden" className="form-control" name="number" value={state.rows[idx].id} onChange={handleAddChange(idx)} />
                                        <input type="text" className="form-control" placeholder="Language Name" name="language_name" value={state.rows[idx].language_name} onChange={handleAddChange(idx)} />
                                    </td>
                                    <td style={{width : '100px'}}>
                                        <select name="reading" id="reading" className="form-control custom-select" onChange={handleAddChange(idx)} value={state.rows[idx].reading}>
                                            <option value="">Select An Option</option>
                                            {
                                                Language.map((items, index) =>
                                                    <option key={++index} value={items.id}>{items.name}</option>
                                                )
                                            }

                                        </select>
                                    </td>
                                    <td style={{width : '100px'}}>
                                        <select name="writing" id="writing" className="form-control custom-select" onChange={handleAddChange(idx)} value={state.rows[idx].writing}>
                                            <option value="">Select An Option</option>
                                            {
                                                Language.map((items, index) =>
                                                    <option key={++index} value={items.id}>{items.name}</option>
                                                )
                                            }

                                        </select>
                                    </td>
                                    <td style={{width : '100px'}}>
                                        <select name="speaking" id="speaking" className="form-control custom-select" onChange={handleAddChange(idx)} value={state.rows[idx].speaking}>
                                            <option value="">Select An Option</option>
                                            {
                                                Language.map((items, index) =>
                                                    <option key={++index} value={items.id}>{items.name}</option>
                                                )
                                            }

                                        </select>
                                    </td>
                                    <td style={{width : '100px'}}>
                                        <select name="listening" id="listening" className="form-control custom-select" onChange={handleAddChange(idx)} value={state.rows[idx].listening}>
                                            <option value="">Select An Option</option>
                                            {
                                                Language.map((items, index) =>
                                                    <option key={++index} value={items.id}>{items.name}</option>
                                                )
                                            }

                                        </select>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <Row gutter={24}>
                            <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Save & Submit</Button>
                            </Col>
                        </Row>

                    </Form>

                </div>
            </div>
        </Card>
    );
}

export default LanguageInfo;