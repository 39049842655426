import React from 'react';

const StaticSelectedData = (name) => {
    let data = [];
    if(name === "depreciation_type") {
        data = [
            {
                id:"Flat Rate",
                name: "Flat Rate"
            },
            {
                id:"Percentage",
                name:"Percentage"
            }
        ];
    } else if(name === "depreciation_calculation_mode"){
        data = [
            {
                id: "Monthly",
                name: "Monthly"
            },
            {
                id: "Quarterly",
                name: "Quarterly"
            },
            {
                id: "Half-Yearly",
                name: "Half-Yearly"
            },
            {
                id: "Yearly",
                name: "Yearly"
            }
        ];
    } else if(name === "business_type") {
        data = [
            {
                id: "Business",
                name: "Business"
            },
            {
                id: "Pavement Professionals",
                name: "Pavement Professionals"
            },
            {
                id: "Supply Chain",
                name: "Supply Chain"
            },
            {
                id: "Transport Business",
                name: "Transport Business"
            }
        ];
    } else if(name === "shipping_method") {
        data = [
            {
                id: "FedEx",
                name: "FedEx"
            },
            {
                id: "USPS",
                name: "USPS"
            },
            {
                id: "DHL",
                name: "DHL"
            }
        ];
    } else if(name === "shipping_tax") {
        data = [
            {
                id: "1",
                name: "1"
            },
            {
                id: "2",
                name: "2"
            },
            {
                id: "2.5",
                name: "2.5"
            },
            {
                id: "3",
                name: "3"
            },
            {
                id: "3.5",
                name: "3.5"
            },
            {
                id: "4",
                name: "4"
            },
            {
                id: "4.5",
                name: "4.5"
            },
            {
                id: "5",
                name: "5"
            }
        ];
    } else if(name === "lead_status") {
        data = [
            {
                id: 'lead',
                name: 'Lead'
            },
            {
                id: 'customer',
                name: 'Customer'
            }
            // {
            //     id: "New",
            //     name: "New"
            // },
            // {
            //     id: "Qualified",
            //     name: "Qualified"
            // },
            // {
            //     id: "Unqualified",
            //     name: "Unqualified"
            // },
            // {
            //     id: "Working",
            //     name: "Working"
            // },
            // {
            //     id: "Nurturing",
            //     name: "Nurturing"
            // }
        ];
    } else if(name === "lead_source") {
        data = [
            {
                id: "Advertisement",
                name: "Advertisement"
            },
            {
                id: "Custom Event",
                name: "Custom Event"
            },
            {
                id: "Employee Referral",
                name: "Employee Referral"
            },
            {
                id: "External Referral",
                name: "External Referral"
            },
            {
                id: "Google AdWords",
                name: "Google AdWords"
            },
            {
                id: "Other",
                name: "Other"
            },
            {
                id: "Purchased List",
                name: "Purchased List"
            },
            {
                id: "Partner",
                name: "Partner"
            },
            {
                id: "Trade Show",
                name: "Trade Show"
            },
            {
                id: "Webinar",
                name: "Webinar"
            },
            {
                id: "Website",
                name: "Website"
            }
        ];
    } else if(name === "industry") {
        data = [
            {
                id: "Agriculture",
                name: "Agriculture"
            },
            {
                id: "Apparel",
                name: "Apparel"
            },
            {
                id: "Banking",
                name: "Banking"
            },
            {
                id: "Biotechnology",
                name: "Biotechnology"
            },
            {
                id: "Chemicals",
                name: "Chemicals"
            },
            {
                id: "Communication",
                name: "Communication"
            },
            {
                id: "Construction",
                name: "Construction"
            },
            {
                id: "Consulting",
                name: "Consulting"
            },
            {
                id: "Education",
                name: "Education"
            },
            {
                id: "Electronics",
                name: "Electronics"
            },
            {
                id: "Energy",
                name: "Energy"
            },
            {
                id: "Energy",
                name: "Energy"
            },
            {
                id: "Engineering",
                name: "Engineering"
            },
            {
                id: "Entertainment",
                name: "Entertainment"
            },
            {
                id: "Environmental",
                name: "Environmental"
            },
            {
                id: "Finance",
                name: "Finance"
            },
            {
                id: "Food & Beverage",
                name: "Food & Beverage"
            },
            {
                id: "Government",
                name: "Government"
            },
            {
                id: "Health Care",
                name: "Health Care"
            },
            {
                id: "Hospitality",
                name: "Hospitality"
            },
            {
                id: "Other",
                name: "Other"
            },
            {
                id: "Recreation",
                name: "Recreation"
            },
            {
                id: "Retail",
                name: "Retail"
            },
            {
                id: "Shipping",
                name: "Shipping"
            },
            {
                id: "Technology",
                name: "Technology"
            },
            {
                id: "Telecommunication",
                name: "Telecommunication"
            },
            {
                id: "Transportation",
                name: "Transportation"
            },
            {
                id: "Utilities",
                name: "Utilities"
            }
        ];
    } else if(name === 'task_status'){
        data = [
            {
                id: "New",
                name: "New"
            },
            {
                id: "Working",
                name: "Working"
            },
            {
                id: "Unqualified",
                name: "Unqualified"
            },
            {
                id: "Done",
                name: "Done"
            }
        ];
    } else if(name === 'event_status'){
        data = [
            {
                id: "Open",
                name: "Open"
            },
            {
                id: "Running",
                name: "Running"
            },
            {
                id: "Close",
                name: "Close"
            }
        ];
    } else if(name === 'call_status'){
        data = [
            {
                id: "Connected",
                name: "Connected"
            },
            {
                id: "Not Reachable",
                name: "Not Reachable"
            },
            {
                id: "Not Connected",
                name: "Not Connected"
            }
        ];
    } else if(name === 'visit_type'){
        data = [
            {
                id: "Sales",
                name: "Sales"
            },
            {
                id: "Technical",
                name: "Technical"
            },
            {
                id: "Others",
                name: "Others"
            }
        ];
    } else if(name === 'visit_status'){
        data = [
            {
                id: "Visited",
                name: "Visited"
            },
            {
                id: "Not Yet",
                name: "Not Yet"
            },
            {
                id: "Canceled",
                name: "Canceled"
            }
        ];
    } else if(name === "blood_group") {
        data = [
            {
                id: "A+",
                name: "A+"
            },
            {
                id: "A-",
                name: "A-"
            },
            {
                id: "B+",
                name: "B+"
            },
            {
                id: "B-",
                name: "B-"
            },
            {
                id: "O+",
                name: "O+"
            },
            {
                id: "O-",
                name: "O-"
            },
            {
                id: "AB+",
                name: "AB+"
            },
            {
                id: "AB-",
                name: "AB-"
            }
        ];
    } else if(name === "gender") {
        data = [
            {
                id: "Male",
                name: "Male"
            },
            {
                id: "Female",
                name: "Female"
            },
            {
                id: "Other",
                name: "Other"
            }
        ];
    } else if(name === "marital_status") {
        data = [
            {
                id: "Married",
                name: "Married"
            },
            {
                id: "Single",
                name: "Single"
            },
            {
                id: "Widowed",
                name: "Widowed"
            }
        ];
    } else if(name === 'identity_type'){
        data = [
            {
                id: "Passport",
                name: "Passport"
            },
            {
                id: "National ID",
                name: "National ID"
            },
            {
                id: "Residential ID",
                name: "Residential ID"
            },
            {
                id: "Driving Licence",
                name: "Driving Licence"
            }
        ];
    } else if(name === 'over_time'){
        data = [
            {
                id: "Allowed",
                name: "Allowed"
            },
            {
                id: "Not Allowed",
                name: "Not Allowed"
            }
        ];
    } else if(name === 'employment_status'){
        data = [
            {
                id: "Full Time Contract",
                name: "Full Time Contract"
            },
            {
                id: "Full Time Intern",
                name: "Full Time Intern"
            },
            {
                id: "Full Time Permanent",
                name: "Full Time Permanent"
            },
            {
                id: "Part Time Contract",
                name: "Part Time Contract"
            },
            {
                id: "Part Time Intern",
                name: "Part Time Intern"
            },
            {
                id: "Part Time Permanent",
                name: "Part Time Permanent"
            }
        ];
    } else if(name === 'skill_level'){
        data = [
            {
                id: "Learning",
                name: "Learning"
            },
            {
                id: "Intermediate",
                name: "Intermediate"
            },
            {
                id: "Expert",
                name: "Expert"
            }
        ];
    } else if(name === 'type'){
        data = [
            {
                id: "Transactional",
                name: "Transactional"
            },
            {
                id: "HR",
                name: "HR"
            },
            {
                id: "Bill",
                name: "Bill"
            },
            {
                id: "Procurement",
                name: "Procurement"
            }
        ];
    } else if(name === 'language'){
        data = [
            {
                id: "Elementary Proficiency",
                name: "Elementary Proficiency"
            },
            {
                id: "Limited Working Proficiency",
                name: "Limited Working Proficiency"
            },
            {
                id: "Professional Working Proficiency",
                name: "Professional Working Proficiency"
            },
            {
                id: "Full Professional Working Proficiency",
                name: "Full Professional Working Proficiency"
            },
            {
                id: "Native or Bilingual Proficiency",
                name: "Native or Bilingual Proficiency"
            }
        ];
    }
    else if(name === 'reporttype'){
        data = [
            {
                id: "periodical",
                name: "Periodical"
            },
            {
                id: "comparative",
                name: "Comparative (Back to Back)"
            },
            {
                id: "comparative2",
                name: "Comparative (Year to Year)"
            }
        ];
    }
    else if(name === 'restriction_type'){
        data = [
            {
                id: "none",
                name: "None"
            },
            {
                id: "with",
                name: "With Restriction"
            },
            {
                id: "without",
                name: "Without Restriction"
            }
        ];
    }
    else if(name === 'restrictiontype'){
        data = [
            {
                id: "none",
                name: "None"
            },
            {
                id: "with",
                name: "With Restriction"
            },
            {
                id: "without",
                name: "Without Restriction"
            }
        ];
    }

    // console.log(name);

    return data;
}

export default StaticSelectedData;