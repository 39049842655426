import React, {useState, useEffect} from "react";
import {Form, Button, Card,  Modal, notification} from 'antd';
import jsonfile from './bill.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import moment from "moment";
import 'moment/locale/es-us';


const BillDetails = (props) => {

    const history = useHistory();

    const [title, setTitle] = useState(jsonfile.addtitle);
    const [hasPayment, setHasPayment] = useState(false);
    const [payments, setPayment] = useState([]);
    const [isApprover, setApprover] = useState(false);
    const [hasBill, setHasbill] = useState(false);
    const [bill, setBill] = useState({});

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

    const showModalCofirm = () => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
        BillStatusUpdate(bill.id,1);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showModalReject = () => {
        setIsRejectModalVisible(true);
    };

    const handleOkReject = () => {
        setIsRejectModalVisible(false);
        BillStatusUpdate(bill.id,3);
    };

    const handleCancelReject = () => {
        setIsRejectModalVisible(false);
    };


    const BillStatusUpdate = (id,status) => {
        document.getElementById("loader").style.display = "block";
        axios.get("vendor/billstatusupdate?bill_id="+id+"&status="+status)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                console.log(res.data);
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    getPODetails();
                }
                else {
                    document.getElementById("loader").style.display = "none";
                    notification.success({
                        message: 'Failed',
                        type: "danger",
                        description: res.data.msg
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    const [loading, setLoading] = useState([]);
    const [polist, setPolist] = useState({
        po_id : 0,
        po_number : "",
        date: moment(),
        due_date: moment(),
        reference_no: "",
        company: "",
        company_id: 0,
        vendor_id: 0,
        vendor: "",
        payable_id: null,
        fund_program_id : null,
        func_expense_id : null,
        restriction_type : null,
        amount : 0,
        description : "",
        remark : "",
        status : 0,
        approved_by : "",
        approved_at : "",
    });



    const poid = props.match.params.id;
    const userID = poid;
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }


    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        document.getElementById("loader").style.display = "block";
            axios.get(Config.apiserver + "vendor/billdetails/" + poid)
                .then((res) => {
                    document.getElementById("loader").style.display = "none";
                    //console.log(res.data);
                    if (res.data.status === 1) {
                        const PODataD = res.data.po;
                        if(res.data.hasBill === 1) {
                            setBill(res.data.data);
                            setHasbill(true);
                            setTitle("Bill Details");
                        }
                        if(res.data.isApprover === 1) {
                            setApprover(true);
                        }
                        if(res.data.hasPayment === 1) {
                            setHasPayment(true);
                            setPayment(res.data.payment);
                        }
                        setPolist({
                            po_number : PODataD.po_number,
                            date: moment(),
                            due_date: moment(),
                            reference_no: "",
                            company: PODataD.company,
                            vendor: PODataD.vendor_name,
                            company_id: PODataD.company_id,
                            vendor_id: PODataD.vendor_id,
                            payable_id: null,
                            fund_program_id : null,
                            func_expense_id : null,
                            restriction_type : null,
                            amount : PODataD.grand_total,
                            description : PODataD.description,
                            remark : PODataD.remark,
                            status : PODataD.status,
                            approved_by : PODataD.approved_user,
                            approved_at : PODataD.approved_at
                        });

                    }
                })
                .catch((error) => {
                    console.log(error);
                    document.getElementById("loader").style.display = "none";
                });

    }

    const ApproveOrReject = (
        <>
            <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>
                {
                    bill.status === 0 ?
                        isApprover === true
                            ? <>
                                <Button id="submitbutton" type="danger" onClick={showModalReject} htmlType="button">Reject</Button>
                                <Button id="submitbutton" type="primary" onClick={showModalCofirm} htmlType="button">Approve</Button>
                            </>
                            : ""
                        : ""
                }
                {
                    bill.status === 1 ?
                        hasPayment === false ?
                            <><Button id="submitbutton" type="primary" onClick={showModalReject} htmlType="button"><Link to={"../newpayment?bill_id="+bill.id}>Payment</Link></Button></>
                            : ""
                        : ""
                }
                <Button type="info" onClick={() => history.goBack()} htmlType="back">Back</Button>
            </div>
        </>
    );


    let totalPaid = 0;

    const BillInfo = (
        <div className="row" style={{padding: '20px'}}>
            <h4>Bill Information</h4>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Bill Number : </label>
                <span> {bill.bill_no}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Description : </label>
                <span> {bill.description}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Date : </label>
                <span> {CisUI().DateFormat(bill.date)}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Due Date : </label>
                <span> {CisUI().DateFormat(bill.due_date)}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Reference No# : </label>
                <span> {bill.reference_no}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Accounts Payable :  </label>
                <span> {bill.payable}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Account : </label>
                <span> {bill.accounts_head}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Fund Program : </label>
                <span> {bill.fund_program}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Functional Expense : </label>
                <span> {bill.functional_expense}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Restriction Type : </label>
                <span> {CisUI().getRestrictionType([bill.restriction_type])}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Bill Amount : </label>
                <span style={{color: 'green'}}> {CisUI().getCurrencyFormated1(bill.amount)}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Remark : </label>
                <span> {bill.remark}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Created By : </label>
                <span> {bill.created_by}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Created at : </label>
                <span> {bill.created_at}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Updated at : </label>
                <span> {bill.updated_at}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Approved/Reject By : </label>
                <span> {bill.approved_user}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Approved/Reject at : </label>
                <span> {bill.approved_at}</span>
            </div>
            <div className="col-md-4">
                <label style={{fontWeight: 'bold'}}>Bill Status : </label>
                <span> {CisUI().getBillSatus(bill.status)}</span>
            </div>

            {
                hasPayment ?
                    <>
                        <h4 style={{marginTop: '20px'}}>Payment Informaiton</h4>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Title</th>
                                    <th>Account</th>
                                    <th>Payment Method</th>
                                    <th>Bank Account</th>
                                    <th>Reference/Check No.</th>
                                    <th style={{textAlign: 'right'}}>Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    payments.map((list, index) => {
                                        totalPaid += parseFloat(list.credit);
                                        return <tr>
                                            <td>{CisUI().DateFormat(list.date)}</td>
                                            <td>{list.title}</td>
                                            <td>{list.account_head}</td>
                                            <td>{list.payment_method}</td>
                                            <td>{list.bank_account}</td>
                                            <td>{list.reference}</td>
                                            <td style={{textAlign: 'right'}}>{list.credit}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th colSpan={6}>Total Paid</th>
                                    <th style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(totalPaid)}</th>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </>
                    : ""
            }

            {ApproveOrReject}


        </div>
    );

    return (
        <Card title={title} extra={
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.list)
                    : CisUI().addAction('../' + jsonfile.urls.list)
            }>

                {CisUI().showLoading}
            {polist.po_id > 0 ?
                <div className="row" style={{padding: '20px'}}>
                    <h4>PO Information</h4>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Company Name : </label>
                        <span> {polist.company}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>PO Number : </label>
                        <span> {polist.po_number}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Vendor Name : </label>
                        <span> {polist.vendor}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>PO Amount : </label>
                        <span style={{fontWeight: 'bold',color: 'green'}}> {CisUI().getCurrencyFormated1(polist.amount)}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>PO Status : </label>
                        <span> {CisUI().getBillSatus(polist.status)}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Approved By : </label>
                        <span> {polist.approved_by}</span>
                    </div>
                    <div className="col-md-4">
                        <label style={{fontWeight: 'bold'}}>Approved at : </label>
                        <span> {polist.approved_at}</span>
                    </div>
                </div>
                : ""
            }

                {BillInfo}


            <Modal title="Alert" visible={isConfirmModalVisible} okText={"Confirm"} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
                <h4>Are you sure you want to approve ?</h4>
            </Modal>

            <Modal title="Alert" visible={isRejectModalVisible} okText={"Confirm"} onOk={handleOkReject} onCancel={handleCancelReject}>
                <h4>Are you sure you want to reject ?</h4>
            </Modal>

        </Card>
    );
};

export default BillDetails;